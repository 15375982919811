import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Detail from './detail'


export default function DetailDialog(props) {
 const [dialogState, setDialogState] = useState(props.dialogState)

 useEffect(() => {
    setDialogState(props.dialogState)
}, [props.dialogState])

  const handleClose = () => {
    props.onClose()
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        fullScreen={props.mobile}
        maxWidth="xl"
        open={dialogState}
        onClose={handleClose}
        aria-labelledby="detail-dialog"
      >
        <DialogContent>
          <Detail 
              selectedPS = {props.selectedPS} 
              psInfos = {props.psInfos}
              onClose = {handleClose}
              mobile = {props.mobile}
              isAdmin = {props.isAdmin}
              />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
