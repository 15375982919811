import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import * as moment from 'moment-timezone'
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

export default function Sandbox() {
    const [all, setAll] = useState([])
    const [oned, setOned] = useState([])
    const [filtred, setFiltred] = useState([])
    const [tosend, setTosend] = useState([])

    const handleClick = () => {
        axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/sandbox2`)
        .then(res => {

            return setOned(res.data.map((el)=>{
                return el.psID
            }))
        }).then(()=>{
           return axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/sandbox`)
        }).then((ded)=>{

            setAll(ded.data.map((el)=>{
                return {psID: el.psID, qte: el.qte}
            }))
        })
    }

    const handleShow = ()=>{

        let data = all
        data = data.filter((el)=>{
            return !oned.includes(el.psID)
        })
        setFiltred(data)

    }

    const handleget2 = ()=>{


    }


    const handleAdd = ()=>{
        filtred.forEach(el => {
            axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/tadil`, {psID: el.psID, newQte: el.qte})
            .then((e)=>{

            })
        });
    }
    
    return(
        <div style={{height:"100%", width:"100%", display:"flex"}}>
          <Button onClick={handleClick} color="primary" variant="outlined">
            1 get!
          </Button>
          <Button onClick={handleShow} color="primary" variant="outlined">
            2 show
          </Button>
          <Button onClick={handleget2} color="primary" variant="outlined">
            3 get 2
          </Button>
          <Button onClick={handleAdd} color="primary" variant="outlined">
            4 add
          </Button>
        </div>
    )
}