import React from 'react';
import { makeStyles} from '@material-ui/core/styles'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        flexGrow: 1,
        flexWrap: "wrap",
        textAlign: "center"
     },
     buttonAdd:{
        marginRight: 10
    },
  }));

export default function Toolbar(props) {
    const classes = useStyles();
    return(
    <div className={classes.toolBar}>
        <h2>Liste des pièces en stock mort</h2>
        <div>
        <TextField
            margin="dense"
            id="annuelle"
            label="Depuis"
            name="annuelle"
            variant="outlined"
            type="number"
            value={props.months}
            onChange={props.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">Mois</InputAdornment>,
              inputProps: { min: 0}
            }}
            style={{width: "8rem"}}
          />
        </div>
    </div>
    )
}