import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';


class Table extends Component {
  constructor(props){
    super()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.data !== nextProps.data) || (this.props.mobile !== nextProps.mobile)
  }

  render(){
  const columns = [
    {
      name: "psID",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
    {
      name: "code",
      label: "Référence",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "exCode",
      label: "ex Reference",
      options: {
       filter: false,
       sort: false,
       display: false,
      }
     },
    {
     name: "name",
     label: "Désignation",
     options: {
      filter: false,
      sort: true,
      searchable: true,
     }
    },
    {
      name: "fournisseur",
      label: "Fournisseur",
      options: {
       filter: true,
       sort: true,
       display: !this.props.mobile,
      }
     },

     {
      name: "machine",
      label: "Equipement/Machine",
      options: {
       filter: true,
       sort: true,
       display: !this.props.mobile,
      }
     },
     {
      name: "zone",
      label: "Zone",
      options: {
       filter: true,
       sort: true,
       display: false,
      }
     },
    {
     name: "codeMagasin",
     label: "Code magasin",
     options: {
      filter: false,
      sort: false,
      display	: false
     }
    },
    {
      name: "posMagasin",
      label: "Position Magasin",
      options: {
       filter: false,
       sort: true,
       display: !this.props.mobile,
       searchable: true,
      }
     },
    {
     name: "postav",
     label: "TAV/POS",
     options: {
      filter: false,
      sort: true,
      display: false,
      searchable: true,
     }
    },
     {
      name: "qte",
      label: "Quantité",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '1rem'}}),
      }
     }, 
     {
      name: "last",
      label: "Dernière sortie",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'right'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '1rem'}}),
      }
     }, 
     {
      name: "annuelle",
      label: "Conso estimée",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display: this.props.psInfos ? false : "excluded",
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     }, 
     {
      name: "alerte",
      label: "Quantité d'alerte",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
     {
      name: "price",
      label: "Prix unitaire",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display: this.props.psInfos ? false : "excluded",
       setCellProps: () => ({style:{textAlign: 'right'}}),
       setCellHeaderProps: () => ({style:{textAlign: 'right'}}),
      }
     },
     {
      name: "unit",
      label: "unit",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
     {
      name: "currency",
      label: "Devise",
      options: {
       filter: true,
       sort: false,
       searchable: false,
       display: this.props.psInfos ? false : "excluded",
      }
     },
     {
      name: "machineID",
      label: "machineID",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
     {
      name: "fournisseurID",
      label: "fournisseurID",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
    ];
 
   const options = {
     selectableRows: 'none',
     search: true,
     searchOpen: true,
     searchPlaceholder: "Rechercher par Désignation, Référence, Machine...",
     print: true,
     download: true,
     rowsPerPage: 60,
     rowsPerPageOptions: [30, 60, 90, 120],
     responsive: 'standard',
     searchText: this.props.searchText,
     onCellClick: (a,meta) => {
      if(meta.colIndex!==19){
        this.props.onRowClick(this.props.data[meta.dataIndex].psID)
      }
     },
     customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      const query = searchQuery.toLowerCase()
      currentRow.forEach(col => {
        if(typeof(col) === "string"){
          if (col.toLowerCase().includes(query)) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    onSearchChange: (searchText) => {
      this.props.onSearchTextChange(searchText)
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Articles par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Pièces(s) sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      }
    }
  })

  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Liste complète des pièces de rechange"}
    data={this.props.data}
    columns={columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
