import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CustomSearchRender from './CustomSearchRender';

class Table extends Component {
  constructor(props){
    super()
    this.state = {
      columns: []
    }
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.data !== nextProps.data) || (this.props.mobile !== nextProps.mobile)
  }

  componentDidMount(){
    this.setState({
      columns: this.columns(this.props.mobile)
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.mobile !== this.props.mobile) {
      this.setState({
        columns: this.columns(nextProps.mobile)
      })
    }
  }

  columns(mobile){
    return [
      {
        name: "psID", //0
        label: "id",
        options: {
         filter: false,
         sort: false,
         display: "excluded",
         searchable: false,
        }
       },
      {
        name: "code", //1
        label: "Référence",
        options: {
         filter: true,
         filterType: "textField",
         sort: false,
         display: !mobile
        }
       },
       {
        name: "exCode", //2
        label: "ex Reference",
        options: {
         filter: false,
         sort: false,
         display: false
        }
       },
      {
       name: "name", //3
       label: "Désignation",
       options: {
        filter: false,
        sort: true,
        searchable: true,
       }
      },
      {
        name: "fournisseur", //4
        label: "Fournisseur",
        options: {
         filter: true,
         sort: true,
         display: !mobile
        }
       },
  
       {
        name: "machine", //5
        label: "Equipement/Machine",
        options: {
         filter: true,
         sort: true,
         display: !mobile
        }
       },
       {
        name: "zone", //6
        label: "Zone",
        options: {
         filter: true,
         sort: true,
         display: false
        }
       },
      {
       name: "codeMagasin", //7
       label: "Code magasin",
       options: {
        filter: true,
        filterType: "textField",
        sort: true,
        display	: false
       }
      },
      {
        name: "posMagasin", //8
        label: "Position Magasin",
        options: {
        filter: true,
        filterType: "textField",
         sort: true,
         searchable: true,
        }
       },
      {
       name: "postav", //9
       label: "TAV/POS",
       options: {
        filter: false,
        sort: true,
        display	: false,
        searchable: true,
       }
      },
       {
        name: "qte", //10
        label: "Quantité",
        options: {
         filter: false,
         sort: true,
         searchable: false,
         setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
         setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
        }
       }, 
       {
        name: "count", //11
        label: "Conso annuelle",
        options: {
         filter: false,
         sort: true,
         searchable: false,
         display: mobile ? false : this.props.psInfos ? true : "excluded",
         setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
         setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
        }
       },
       {
        name: "annuelle", //12
        label: "Conso estimée",
        options: {
         filter: false,
         sort: true,
         searchable: false,
         display: this.props.psInfos ? false : "excluded",
         setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
         setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
        }
       }, 
       {
        name: "alerte", //13
        label: "Quantité d'alerte",
        options: {
         filter: false,
         sort: false,
         searchable: false,
         display	: "excluded",
        }
       },
       {
        name: "price", //14
        label: "Prix unitaire",
        options: {
         filter: false,
         sort: true,
         searchable: false,
         display: this.props.psInfos ? false : "excluded",
         setCellProps: () => ({style:{textAlign: 'right'}}),
         setCellHeaderProps: () => ({style:{textAlign: 'right'}}),
        }
       },
       {
        name: "unit", //15
        label: "unit",
        options: {
         filter: false,
         sort: false,
         searchable: false,
         display	: "excluded",
        }
       },
       {
        name: "currency", //16
        label: "Devise",
        options: {
         filter: true,
         sort: false,
         searchable: false,
         display: this.props.psInfos ? false : "excluded",
        }
       },
       {
        name: "machineID", //17
        label: "machineID",
        options: {
         filter: false,
         sort: false,
         searchable: false,
         display	: "excluded",
        }
       },
       {
        name: "fournisseurID", //18
        label: "fournisseurID",
        options: {
         filter: false,
         sort: false,
         searchable: false,
         display	: "excluded",
        }
       },
       {
        name: "Actions", //19
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: (this.props.token.isAdmin || this.props.token.change) ? !mobile : "excluded",
          setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
          setCellProps: () => ({style:{textAlign: 'center'}}),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
          <div style={{width: "7rem"}}>
            <IconButton 
              aria-label="changeButton"
              onClick={() => {
                this.props.handleOpen(tableMeta.rowData[0])
              }}
              >
              <SwapHorizIcon fontSize="large" />
            </IconButton>
            {this.props.token.isAdmin &&
            <IconButton 
              aria-label="editButton"
              onClick={() => {
                this.props.handleEdit(tableMeta.rowData)
              }}
              >
              <EditIcon/>
            </IconButton>
          }
        </div>  
            );
          }
        }
      },
      {
        name: "type",
        label: "Type de pièce",
        options: {
         filter: true,
         searchable: false,
         display	: "excluded",
        }
       },        
     ];
  }

  render(){
   const options = {
     selectableRows: 'none',
     search: true,
     searchOpen: true,
     print: true,
     download: this.props.token.isAdmin,
     rowsPerPage: 60,
     rowsPerPageOptions: [60, 120, 250],
     responsive: 'standard',
     tableBodyHeight: '100%',
     searchText: this.props.searchText,
     fixedHeader: true,	
     customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
        />
      );
     },
     onCellClick: (a,meta) => {
      if(meta.colIndex!==19){
        this.props.onRowClick(this.props.data[meta.dataIndex].psID)
      }
     },
     customSearch: (searchQuery, currentRow) => {
      if(currentRow.toString().replace(/[-/:]/g,'').toLowerCase().split(" ").join("").indexOf(searchQuery.replace(/[-/:]/g,'').split(" ").join("").toLowerCase()) >= 0 )  return true
      return false;
    },
    onSearchChange: (searchText) => {
      this.props.onSearchTextChange(searchText)
     },
     onTableChange: (action, tableState) =>{
      if(action==="viewColumnsChange"){
        let columns = this.state.columns
        columns = columns.map((el)=>{
          el.options.display = tableState.columns.find(x => x.name === el.name).display
          return el
        })  
        this.setState({
          columns: columns
        })
      }
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce à afficher",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Pièces(s) sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },

    },
    setRowProps: (row) => {
      let style = {}
      if(row[10] <= 0){
        style["color"] = "white"
        style["backgroundColor"] = "#ff4040"
      }else if(row[10] <= row[13]){
        style["backgroundColor"] = "#ffa926"
      }
      if(row[20] === "Importante" && row[10] <= row[13]){
        style["borderStyle"] = "solid"
        style["borderColor"] = "yellow"
        style["borderWidth"] = "2px"
      }
      return {
        style: style
      }
   }
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
          minWidth: "100px",
          boxSizing: "border-box"
        },
      }
    }
  })

  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Liste complète des pièces de rechange"}
    data={this.props.data}
    columns={this.state.columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
