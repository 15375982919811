import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";


const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(){
      super()
      this.state = {
        dialogState: false,
    }
    this.handleClickCommand = this.handleClickCommand.bind(this)
  }

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };
4
  handleClickCommand = () => {
    const selects = this.props.selectedRows.data.map(item => item.dataIndex)
    const displayData = this.props.displayData.filter((item) => {
      return selects.includes(item.dataIndex); 
    });
    const filtredData = displayData.map(item => {
      let ps = {}
      ps["psID"] = item.data[0]
      ps["code"] = item.data[1]
      ps["name"] = item.data[3]
      ps["magasin"] = item.data[10]
      ps["qte"] = item.data[12]
      ps["unit"] = item.data[16]
      ps["annuelle"] = item.data[13]
      ps["price"] = item.data[15]
      return ps
    })

    this.props.onChangePanier(filtredData)
    this.props.setSelectedRows([])
  }
  

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Commander les articles selectionnés"}>
          <Button
            variant="contained"
            style={{fontWeight: "bolder"}}
            onClick={this.handleClickCommand}
            startIcon={<AddShoppingCartIcon />}
          >
            Ajouter au panier
          </Button>
        </Tooltip>

      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);