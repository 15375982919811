import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbarSelect from "./CustomToolbarSelectList";

class Table extends Component {
  constructor(props){
    super()
  }

  onListDelete = (data) => {
    this.props.onListDelete(data)
  }

  onListArchive = (data) => {
    this.props.onListArchive(data)
  }

  onEditSubmit = (data) => {
    this.props.onEditSubmit(data)
  }

  render(){
  const columns = [
    {
      name: "listID",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
    {
     name: "name",
     label: "Titre",
     options: {
      filter: false,
      sort: true,
      searchable: true,
     }
    },
    {
      name: "description",
      label: "Description",
      options: {
       filter: false,
       sort: false,
       searchable: true,
      }
     },
     {
      name: "date_text",
      label: "Date de commande",
      options: {
       filter: false,
       sort: false,
       searchable: false,
      }
     },
     {
      name: "date",
      label: "Date de commande",
      options: {
        display: "excluded",
      }
     },
   ];
 
   const options = {
     filterType: 'textField',
     selectableRows: this.props.isAdmin ? 'single' : 'none',
     search: true,
     searchOpen: true,
     searchPlaceholder: "Rechercher par commande ou description...",
     print: false,
     download: false,
     rowsPerPage: 60,
     rowsPerPageOptions: [30, 60, 90, 120],
     responsive: 'scrollFullHeight',
     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect 
            selectedRows={selectedRows} 
            displayData={displayData} 
            onListDelete={this.onListDelete} 
            onListArchive={this.onListArchive} 
            onEditSubmit={this.onEditSubmit}
            setSelectedRows={setSelectedRows} />
    ),
     onCellClick: (a,meta) => {
      if(meta.dataIndex || meta.dataIndex===0){
        this.props.onRowClick(this.props.data[meta.dataIndex].listID)
      }
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Commande séléctionée",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },
    setRowProps: (row) => {
      let style = {}
      if(row[10] <= 0){
        style["color"] = "white"
        style["backgroundColor"] = "#ff4040"
      }else if(row[10] <= row[12]){
        style["backgroundColor"] = "#ffa926"
      }
      return {
        style: style
      }
   }
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      }
    }
  })
   
  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Liste à commander"}
    data={this.props.data}
    columns={columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
