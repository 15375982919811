import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles} from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import {
    DatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';

import * as moment from 'moment-timezone'
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");


const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        flexGrow: 1,
        flexWrap: "wrap",
        textAlign: "center",
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2),
          },
     },
     buttonAdd:{
        marginRight: 10
    }

  }));

export default function Toolbar(props) {
    const classes = useStyles();
    const [locale, setLocale] = useState("fr");    

    return(
    <div className={classes.toolBar}>
        <h2>Historique des entrées/sorties</h2>
        <div style={{display:"flex", alignItems:"center", flexWrap: "wrap"}}>
        <div >
            <FormControlLabel control={<Checkbox checked={props.entrees} name="entrees" color="primary" onChange={props.handleChange}/>} label="Entrées"/>
            <FormControlLabel control={<Checkbox checked={props.sorties} name="sorties" color="primary" onChange={props.handleChange}/>} label="Sorties"/>
            <FormControlLabel control={<Checkbox checked={props.corrections} name="corrections" color="primary" onChange={props.handleChange}/>} label="Corrections"/>
        </div>
        <div style={{display: "flex"}}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
            <DatePicker
                value={props.startDate}
                disableFuture
                onChange={props.handleChangeStart}
                label="Date de debut"
                invalidDateMessage="Date invalide"
                maxDateMessage="La Date ne doit pas dépasser la date d'aujourd'hui"
                okLabel="Selectioner"
                cancelLabel="Annuler"
                format="DD/MM/YYYY"
                name="startDate"
                style={{marginRight: "1rem"}}
            />
            <DatePicker
                value={props.endDate}
                disableFuture
                onChange={props.handleChangeEnd}
                label="Date de fin"
                invalidDateMessage="Date invalide"
                maxDateMessage="La Date ne doit pas dépasser la date d'aujourd'hui"
                okLabel="Selectioner"
                cancelLabel="Annuler"
                format="DD/MM/YYYY"
                name="endDate"
                showTodayButton
                todayLabel = "Aujourd'hui"
                style={{marginRight: "1rem"}}
            />
        </MuiPickersUtilsProvider>
            <Button variant="contained" onClick={props.handleOpenDoc} className={classes.buttonAdd} title="Télecharger l'historique">
                <DescriptionIcon/> 
            </Button>
            </div>
        </div>
    </div>
    )
}