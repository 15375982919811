import React, {useState, useEffect} from 'react';
import Creatable from 'react-select/creatable';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { useSnackbar } from 'notistack';
import axios from 'axios';


export default function SelectModule(props) {

  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [isLoading, setIsLoadding] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(props.update){
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/${props.from}`, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        const ops = res.data.map((el) => {
          const obj = {}
          obj["value"]= el.id
          obj["label"]= el.name + " " + (el.code ? el.code : "")
        return obj
        })
        setOptions(ops)
      })
    }
  }, [props.update]);

  const formatCreateLabel = inputValue => (
    <span style={{ fontWeight: "bold" }}>Ajouter {inputValue}</span>
  );

  const createOption = (label) => ({
    value: label.id,
    label: label.name,
  });

  const handleCreate = (inputValue) => {
    setIsLoadding(true)
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/${props.from}`, {name: inputValue.toUpperCase()}, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        const newOption = createOption(res.data);
        enqueueSnackbar(props.from.slice(0, -1) + ' créé avec succès', {variant:"success"});
        setOptions(options => [...options, newOption])
        props.onChange(newOption)
        setIsLoadding(false)
      })
  };

  useEffect(() => {
      setSelectedOption([props.selectedOption])
  }, [props.selectedOption]);

    return (
      <FormGroup style={{width: "100%", marginRight: "10px", marginTop: "10px"}}>
      <InputLabel style={{marginBottom: "8px"}}>{props.title}</InputLabel>
      <Creatable
        value={options.filter((el)=>{
          return selectedOption.includes(el.value)
        })}
        onChange={props.onChange}
        onCreateOption={handleCreate}
        options={options}
        isDisabled={isLoading}
        menuPosition="fixed"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        formatCreateLabel={formatCreateLabel}
        placeholder="Rechercher..."
      />
      </FormGroup>
    );
}