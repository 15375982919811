import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(<SnackbarProvider maxSnack={3} autoHideDuration={2000}>
                    <Router>
                        <App />
                    </Router>
                </SnackbarProvider>, document.getElementById('root'));
                
serviceWorker.register();
