import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar'
import DetailDialog from './../list_pieces/detailDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';
import Decode from 'jwt-decode'
import axios from 'axios'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import * as moment from 'moment-timezone'
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

function DeadPS() {

  const [data, setData] = useState([])
  const [tableData, setTabledata] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedPS, setselectedPS] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [loadded, setLoadded] = useState(true)
  const [months, setMonths] = useState(3)
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const raw_token = localStorage.getItem("token")
  let token
  if(raw_token) token = Decode(raw_token)  

  useEffect(()=>{
    getData()
    window.addEventListener('hashchange', handleOnHashChange);
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal"){
      onCloseDetail()
    }
  }; 

  const handleChange =  (e) => {
    console.log(e.target.value)
    if(e.target.value){
      setMonths(e.target.value)
      setTabledata(resort(data, e.target.value))
    }else{
      setMonths()
      setTabledata(resort([], 1))
    }

  }

  const resort = (d, m) => {
    const lastYear = moment().subtract(m, 'months')
    let newData = [...d]
    newData = newData.filter((el)=>{
      const {last_moment, first_moment} = el
      return (last_moment && last_moment.isBefore(lastYear)) || (!last_moment && first_moment.isBefore(lastYear))
    })
    newData = newData.sort((a,b) => (a.last_moment ? a.last_moment.format('YYYYMMDD') : "") - (b.last_moment ? b.last_moment.format('YYYYMMDD') : ""))
    return newData
  }

  const onSearchTextChange =  (text) => {
    setSearchText(text)
  }

  const getData = ()=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/dead_ps_min`)
    .then(cnt =>{
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/dead_ps_max`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const data1 = res.data.map((el)=>{
            el["currency"] = el.currency==="E"?"€":"DZD"
            if(el.maxDate){
              el["last"] = moment(el.maxDate).format("DD/MM/YYYY")
              el["last_moment"] = moment(el.maxDate)
            }else{
              el["last_moment"] = moment("01-01-1970")
            }
            if(el.machine) el["machine"] =  el.machine.name
            if(el.fournisseur) el["fournisseur"] =  el.fournisseur.name
            if(el.postav){
              const postav = el.postav.split(";")
              el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            return el
          })
          const data2 = cnt.data.map((el)=>{
            el["currency"] = el.currency==="E"?"€":"DZD"
            if(el.minDate){
              el["first_moment"] = moment(el.minDate)
            }
            if(el.machine) el["machine"] =  el.machine.name
            if(el.fournisseur) el["fournisseur"] =  el.fournisseur.name
            if(el.postav){
              const postav = el.postav.split(";")
              el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            return el
          })

          let data = data2.map(obj => {
            let data = data1.find(item => item.psID === obj.psID);
            return {...obj, ...data}
        });
          setLoadded(false)
          setData(data)
          setTabledata(resort(data, months))
        }).catch(e=>{
          if(e.response){        
            enqueueSnackbar(e.response.data, {variant:"error"});  
      
          } else {
            enqueueSnackbar('Serveur indisponible', {variant:"error"});
          }
        })
    }).catch(err=>{
      enqueueSnackbar('Serveur indisponible', {variant:"error"});
    }) 
  }

  const onRowClick = (row) => {
    setselectedPS(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedPS(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
    }
    
  return (
    //ajouter un raccourci ctrl + n pour ajouter une piece
    <React.Fragment>
      <div style={{marginBottom: "2rem"}}>
        <Toolbar 
            handleChange = {handleChange}
            months = {months}
        />
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {token.psInfos}
            mobile = {mobile}
        />
        {loadded &&
           <LinearProgress />   
        } 
        <TablePieces 
            data={tableData}
            onRowClick={onRowClick}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            isAdmin = {token.isAdmin}
            psInfos = {token.psInfos}
            mobile = {mobile}
            />
        </div>
         
    </React.Fragment>
  );
}

export default DeadPS;
