import React, { Component , Fragment } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import CustomToolbarSelect from "./CustomToolbarSelectCommand";


class Tableau extends Component {
  constructor(props){
    super()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.data !== nextProps.data) || (this.props.mobile !== nextProps.mobile) || (this.props.total !== nextProps.total)
  }

  onDelete = (data) => {
    this.props.onDelete(data)
  }

  onProformat = (data) => {
    this.props.onProformat(data)
  }

  onRecu = (data) => {
    this.props.onRecu(data)
  }

  onEdit = (data) => {
    this.props.onEdit(data)
  }


  render(){

  const columns = [
    {
      name: "comID",//0
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "psID",//1
      label: "psID",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "listID",//2
      label: "listID",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "commande",//3
      label: "Commande",
      options: {
       filter: false,
       sort: true,
       display: this.props.detailVersion ? "true" : "excluded"
      }
     },
     {
      name: "code",//4
      label: "Référence",
      options: {
       filter: false,
       sort: true,
       display: this.props.detailVersion ? "excluded" : "true"
      }
     },
     {
      name: "postav",//5
      label: "TAV/POS",
      options: {
       filter: true,
       sort: true,
       filterType: "textField",
       display: this.props.detailVersion ? "excluded" : !this.props.mobile,
      }
     },
     {
      name: "piece",//6
      label: "Piece",
      options: {
       filter: true,
       sort: true,
       display: this.props.detailVersion ? "excluded" : "true"
      }
     },
     {
      name: "codeMagasin",//7
      label: "Code magasin",
      options: {
       filter: true,
       sort: true,
       filterType: "textField",
       display: this.props.detailVersion ? "excluded" : false
      }
     },
     {
       name: "posMagasin",//8
       label: "Position Magasin",
       options: {
        filter: true,
        sort: true,
        filterType: "textField",
        display: this.props.detailVersion ? "excluded" : "true"
       }
      },
      {
        name: "machine",//9
        label: "Machine/Equipement",
        options: {
          filter: true,
          sort: true,
          display: this.props.detailVersion ? "excluded" : false
        }
      },
      {
       name: "date",//10
       label: "Date",
       options: {
        filter: false,
        sort: false,
        display: this.props.detailVersion ? "true" : "excluded" 
       }
      },
     {
      name: "oldQte",//12
      label: "Quantité magasin",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display: this.props.detailVersion ? "excluded" : !this.props.mobile,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
    },
    {
    name: "count",//14
    label: "Conso. annuelle",
    options: {
      filter: false,
      sort: true,
      display: this.props.detailVersion ? "excluded" : true,
      setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
      setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
    }
    },
    {
      name: "annuelle",//13
      label: "Conso. estimée",
      options: {
        filter: false,
        sort: true,
        searchable: false,
        display: this.props.detailVersion ? "excluded" : true,
        setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
        setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
    },
    {
      name: "qte",//11
      label: "Quantité commandée",
        options: {
          filter: false,
          sort: true,
          searchable: false,
          setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
          setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
        }
     },
     {
      name: "unit",//15
      label: "Unité",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display: this.props.detailVersion ? "excluded" : "true"
      }
     },
     {
      name: "price",//16
      label: "Prix unitaire",
      options: {
       filter: false,
       sort: true,
       searchable: false,

      }
     },
     {
      name: "montant",//17
      label: "Montant",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display: !this.props.mobile,
       setCellProps: () => ({style:{textAlign: 'left'}}),
       setCellHeaderProps: () => ({style:{textAlign: 'left'}}),
      }
    },
    {
      name: "state",//18
      label: "State",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display: "excluded",
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "comment",//19
      label: "Commentaire",
      options: {
       filter: false,
       sort: false,
       display: 'excluded'
      }
     },
     {
      name: "currency",//20
      label: "currency",
      options: {
       filter: false,
       sort: false,
       display: 'excluded'
      }
     },
   ];
 
   const options = {
     search: true,
     searchOpen: true,
     searchPlaceholder: "Rechercher par Désignation, Référence, Machine...",
     print: true,
     download: true,
     filter: true,
     rowsPerPage: 60,
     rowsPerPageOptions: [30, 60, 90, 120],
     responsive: 'scrollFullHeight',
     fixedHeader: true,	
     selectableRows: this.props.detailVersion ? "none" : this.props.isAdmin ? "multiple" : "none",
     rowsSelected: this.props.rowsSelected,
     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect 
              selectedRows={selectedRows} 
              displayData={displayData} 
              setSelectedRows={setSelectedRows} 
              onDelete = {this.onDelete}
              onProformat = {this.onProformat}
              onRecu = {this.onRecu}
              onEdit={this.onEdit}
              command = {this.props.command}
              />
    ),
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      this.props.onRowsSelected(allRowsSelected)
    },
     onCellClick: (a,meta) => {
       if(!this.props.detailVersion){
        if(meta.dataIndex || meta.dataIndex===0){
          this.props.onRowClick(this.props.data[meta.dataIndex].psID)
         }
       }
     },

     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },    
    setRowProps: (row) => {
      if(!this.props.detailVersion){
      let style = {}
      if(row[18] <= 1){
        style["backgroundColor"] = "white"
      }else if(row[18] === 2){
        style["color"] = "black"
        style["backgroundColor"] = "#69c4ff"
      }else if(row[18] === 3){
        style["color"] = "black"
        style["backgroundColor"] = "#6dde80"
      }
      return {
        style: style
      }
    }
   }
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          top: "64px!important",
        }
      },
      MUIDataTableSelectCell: {
        fixedHeader: {
          top: "64px!important",
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          position: "sticky",
          top: "143px",
          zIndex: "999"
        }
      }
    }
  })

   
  return (
    <Fragment>
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Liste des pièces de la commande"}
        data={this.props.data}
        columns={columns}
        options={options}
      />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
    </MuiThemeProvider>

    {this.props.detailVersion ||
    <div style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
    <TableContainer component={Paper} style={{maxWidth: "350px"}}>
      <Table>
        <TableBody>
          <TableRow>
              <TableCell className="total">Montant Total</TableCell>
              <TableCell align="right" className="total">{this.props.total}</TableCell>
          </TableRow>
        </TableBody>
    </Table>
      </TableContainer>
    </div>
    }
    </Fragment>
  );
}
}

export default Tableau;
