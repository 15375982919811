import React, {useState, useEffect, Fragment} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NumericInput from 'react-numeric-input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import SelectModule from './select_ps'
import * as moment from 'moment-timezone'
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

export default function ChangeDialog(props) {

    const initialState = {
        qte: 0,
        code: "",
        codeMagasin: "",
        posMagasin: "",
        zone: "",
        name: "",
        machineName: "",
        fournisseur: "",
        comment: "",
        psID: 0,
        alerte: 0,
        unit: "U"
    }

  const [data, setData] = useState(initialState)
  const [dialogState, setDialogState] = useState(props.dialogState)
  const [locale, setLocale] = useState("fr");
  const [selectedDate, setSelectedDate] = useState(moment().subtract(2, 'days').toDate());
  const [selectedPs, setSelectedPs] = useState(null)
  const [type, setType] = useState(0);
  const [newQte, setNewQte] = useState(0);
  const [delta, setDelta] = useState(1);
  const {editData} = props

  useEffect(() => {
      setDialogState(props.dialogState)
      setData({
           ...data,
           ...editData
      })
      if(editData.qte <= 0) {
        setType(2)
        setDelta(1)
      }else if(editData.qte < 1) {
        setType(0)
        setDelta(editData.qte)
      }else {
        setType(0)
        setDelta(1)
      }
      getNewQte()
  }, [props.dialogState, props.editData])

  useEffect(() => {  
    getNewQte()
    if(isNaN(delta)) setDelta()
}, [type, delta])

  const getNewQte = () => {
    if(type === 0) setNewQte((editData.qte - delta).toFixed(2))
    else setNewQte((editData.qte + delta).toFixed(2))
  }

  const handleChangeDate = (event) => {
    setSelectedDate(event)
  }

  const handleChangeType = (event) => {
    setType(parseInt(event.target.value))
    setDelta(1)
  }

  const handleChangeComment = (event) => {
    setData({...data, comment: event.target.value})
  }

  const handleChangeQte = (event) => {
    if(type !== 0){
        if(event >= 0){
            setDelta(parseFloat(event))
        }
    }else {
        if(event >= 0 && data.qte-event>=0){
            setDelta(parseFloat(event))
        }
    }
  }

  const handleSelectPs = selected => {
    setSelectedPs(selected.value)
    props.onSelectPs(selected.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let text = data
    text["delta"] = delta
    text["newQte"] = newQte
    text["action"] = type
    text["date"] = moment(selectedDate).hour(9).toDate()
    props.onChangeSubmit(text)
    props.onClose()
    setData(initialState)
  }

  const handleAdd = (event) => {
    event.preventDefault()
    document.getElementById("bulkInput").focus()
    let text = data
    text["delta"] = delta
    text["newQte"] = newQte
    text["action"] = type
    text["date"] = moment(selectedDate).hour(9).toDate()
    setData(initialState)
    setSelectedPs()
    props.onChangeSubmit(text)
  }

  const handleClose = () => {
    setData(initialState)
    setDelta(1)
    setNewQte(0)
    setType(0)
    setSelectedPs()
    props.onClose()
  }

  const qteStyle = (value) => {
    let color = "black"
    if(value<=0) color =  "red"
    else if(value<=data.alerte) color =  "#FF4500"
    return {
        color: color,
    }
  }

  const noQte = {
    borderStyle: "dotted", 
    borderColor: "red", 
    borderWidth: "3px", 
    borderRadius: "5px", 
    paddingRight: "15px"
  }

  const newQteStyle = {...qteStyle(newQte),...{fontSize: '1.1rem', marginLeft: '2rem'}}
  const dateMoment = moment(selectedDate)

  return (
    <div>
      <Dialog 
          open={dialogState} 
          onClose={handleClose} 
          fullWidth 
          fullScreen={props.mobile}
          aria-labelledby="form-dialog-title"
          onKeyDown={(e) => {
            if(e.keyCode===13 && !props.bulkChange) handleSubmit(e)
            else if(e.keyCode===27) handleClose(e)
        }}   
          >
        <DialogContent>
        <Typography variant="subtitle1" gutterBottom >
            Déclarer une entrée/sortie de pièce
        </Typography>
        {props.bulkChange &&
        <SelectModule 
              onChange={handleSelectPs}
              selectedOption={selectedPs} 
              update={dialogState}
              data={props.data}
            /> 
        }
        {data.name &&
        <Fragment>
        <Typography variant="h6" gutterBottom >
            {data.name}
        </Typography>
        <Typography variant="subtitle2" gutterBottom >
            {data.machine && data.machine.name} {data.fournisseur && data.fournisseur.name} {data.zone && <span>- Zone {data.zone}</span>} 
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {data.code && <span>Ref: </span>} {data.code} {data.codeMagasin && <span> - Cod.Magasin: </span>} {data.codeMagasin} - <span style={qteStyle(data.qte)}>Quantité magasin: {data.qte} {data.unit}</span>
        </Typography>
        {data.qte<=0 &&
          <Typography variant="subtitle1" style={{color: "red"}}>
            Quantité épuisée
          </Typography>
        }
        <Typography variant="subtitle2" gutterBottom style={{marginTop: "1.2rem"}}>
            Type de déclaration: 
        </Typography>

        <RadioGroup style={{display: "flex", justifyContent: "space-between", marginBottom: "0.8rem"}}row aria-label="periodique" name="type" value={type} onChange={handleChangeType}>
              <FormControlLabel value={0} disabled={data.qte<=0}  control={<Radio />} label="Sortie" />
              <FormControlLabel style={data.qte<=0 ? noQte : {}} value={2} control={<Radio />} label="Entrée" />
              <FormControlLabel value={1} control={<Radio />} label="Retour" />              
          </RadioGroup>

        <FormGroup row style={{alignItems: "center"}}>
        <NumericInput 
            autoFocus = {!props.bulkChange}
            required
            step={data.unit==="ML"? 0.5:1} 
            precision={data.unit==="ML"? 2:0} 
            value={delta}
            min={0}
            max={type === 0 ? data.qte : 10000}
            onChange={handleChangeQte}
            style={{
              input:{
                fontSize: "1.2rem",
                width: "10rem",
                fontFamily: "roboto"
              },
              }}
           />
        <Typography variant="h6" gutterBottom style={newQteStyle}>
            <Fragment>Nouvelle quantité</Fragment>: {newQte} {data.unit}
        </Typography>
        </FormGroup>

        <TextField
            margin="dense"
            id="comment"
            label="Commentaire"
            name= "comment"
            placeholder="Ajouter un commentaire"
            onChange={handleChangeComment}
            value={data.comment}
            fullWidth
            style={{marginBottom: "1rem"}}
          />

        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
                value={selectedDate}
                disableFuture
                disableToolbar
                onChange={handleChangeDate}
                allowKeyboardControl={true}
                label="Date"
                invalidDateMessage="Date invalide"
                maxDateMessage="La Date ne doit pas dépasser la date d'aujourd'hui"
                okLabel="Selectioner"
                cancelLabel="Annuler"
                fullWidth
                format="DD/MM/YYYY"
                style={{marginBottom: "1rem"}}
            />
        </MuiPickersUtilsProvider>
         {props.bulkChange &&
          <Button onClick={handleAdd} disabled={!delta || dateMoment.isAfter() || !dateMoment.isValid()} fullWidth variant="outlined" color="primary">
              Ajouter
          </Button>
         }
          </Fragment>
          }
        </DialogContent>
        <DialogActions>          
            {props.bulkChange ? 
              <Button onClick={handleClose} color="primary">Fermer</Button>:
              <Button onClick={handleClose} color="primary">Annuler</Button>
            }          
          {props.bulkChange ||
          <Button disabled={!delta || dateMoment.isAfter() || !dateMoment.isValid()}  onClick={handleSubmit} color="primary">
            Valider
          </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
