import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import * as moment from 'moment-timezone'
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

export default function AddDialog(props) {
  const initialState = {
    listID: '',
    name: '',
    description: '',
   }
  
  const handleChange = (event) => {
    const {name, value} = event.target
    setData({...data, [name]: value})
  }

  const handleClose = () => {
    props.handleClose()
    setData(initialState)
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    props.onFormSubmit({...data, date: selectedDate.toDate()})
    props.handleClose()
    setData(initialState)
    setSelectedDate(moment())
  }

  const handleEdit = (event) => {
    event.preventDefault()
    props.onFormEdit({...data, date: selectedDate.toDate()})
    props.handleClose()
    setData(initialState)
    setSelectedDate(moment())
  }

  const handleChangeDate = (event) => {
    setSelectedDate(event)
  }

  useEffect(() => {
     setDialogState(props.dialogState)
   }, [props.dialogState])

   useEffect(() => {
    if(props.isEdit){
        setData({
        listID: props.editPs[0],
        name: props.editPs[1],
        description: props.editPs[2]
      })
      setSelectedDate(moment(props.editPs[4]))
    }
   }, [props.isEdit])

   const [dialogState, setDialogState] = useState(props.dialogState)

   const [data, setData] = useState(initialState)

   const {listID, name, description} = data

   const {isEdit} = props

   const [locale, setLocale] = useState("fr");
   const [selectedDate, setSelectedDate] = useState(moment());

   return (
    <div>
      <Dialog 
          open={dialogState} 
          onClose={handleClose} 
          fullWidth 
          aria-labelledby="form-dialog-title"
         >
        <DialogContent style={{marginBottom: "2rem"}}>
        {isEdit?
            <Typography variant="h6" gutterBottom >Editer la commande {name}</Typography>:
            <Typography variant="h6" gutterBottom >Créer une nouvelle commande</Typography>
          }

          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Nom de la commande"
            name="name"
            value={name}
            onChange={handleChange}
            fullWidth
          />
         <TextField
            margin="dense"
            id="description"
            label="Description"
            name="description"
            value={description}
            onChange={handleChange}
            fullWidth
          />
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
            <KeyboardDatePicker
                value={selectedDate}
                onChange={handleChangeDate}
                allowKeyboardControl={true}
                label="Date de commande"
                invalidDateMessage="Date invalide"
                okLabel="Selectioner"
                cancelLabel="Annuler"
                fullWidth
                format="DD/MM/YYYY"
                style={{marginTop: "1rem"}}
            />
        </MuiPickersUtilsProvider>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>

          {isEdit? 
            <Button onClick={handleEdit} color="primary" disabled={data.name === ""  || !selectedDate || !selectedDate.isValid()}>
            Editer
          </Button> :
            <Button onClick={handleSubmit} color="primary" disabled={data.name === "" || !selectedDate || !selectedDate.isValid()}>
            Ajouter
          </Button>
          }  

          </DialogActions>
      </Dialog>
    </div>
  );
}