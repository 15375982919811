import React, { Component , Fragment } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import IconButton from '@material-ui/core/IconButton';
import Confirm from '../confirmDialog'

class Table extends Component {
  constructor(props){
    super()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.data !== nextProps.data ? true : false;
  }

  render(){
  const columns = [
{
    name: "action",
    label: "Action",
    options: {
        filter: true,
        sort: true,
        empty: true,
        setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
        setCellProps: () => ({style:{textAlign: 'center'}}),
        customBodyRender: (value) => {
        return (
            <Fragment>
              {value === 0 ?
              <Tooltip  title="Sortie">
                  <RemoveCircleOutlineIcon fontSize="large" style={{color:"red"}}/> 
              </Tooltip >           
              : value === 1 ? 
              <Tooltip title="Retour">
                  <RotateLeftIcon fontSize="large" style={{color:"orange"}} />
              </Tooltip>
              : value === 2 ?   
              <Tooltip title="Entrée">         
                  <ControlPointIcon fontSize="large" style={{color:"green"}}/>
              </Tooltip>
              : value === 3 ? 
              <Tooltip title="Correction">
                  <ErrorOutlineIcon fontSize="large" style={{color:"purple"}}/>
              </Tooltip>
              : <Tooltip title="Initial">
                    <AddCircleIcon fontSize="large" style={{color:"green"}}/>
                </Tooltip>
              }
            </Fragment>
        );
        }
    }
    },  
    {
      name: "historyID",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "psID",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "codeMagasin",
      label: "Code Magasin",
      options: {
       filter: true,
       sort: false,
       display: this.props.detailVersion ? "excluded" : !this.props.mobile 
      }
     },
     {
      name: "piece",
      label: "Piece",
      options: {
       filter: true,
       sort: true,
       display:  this.props.detailVersion ? "excluded" : true
      }
     },
     {
      name: "machine",
      label: "Machine/Equipement",
      options: {
       filter: true,
       sort: true,
       display:  this.props.detailVersion ? "excluded" : !this.props.mobile 
      }
     },
     {
      name: "fournisseur",
      label: "Fournisseur",
      options: {
       filter: true,
       sort: true,
       display: this.props.detailVersion ? "excluded" : !this.props.mobile 
      }
     },
     {
      name: "zone",
      label: "Zone",
      options: {
       filter: true,
       sort: true,
       display: false
      }
     },
    {
      name: "delta",
      label: "Quantité E/S",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "newQte",
      label: "Nouvelle quantité",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display: !this.props.mobile,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
    {
      name: "date",
      label: "Date",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "comment",
      label: "Commentaire",
      options: {
      display: true,
       filter: false,
       sort: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "Annuler",
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: this.props.detailVersion ? "excluded" : this.props.token.change ? !this.props.mobile : "excluded",
        setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
        setCellProps: () => ({style:{textAlign: 'center'}}),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
        <Confirm title={`Etes-vous sûr de vouloir annuler cette opération ?`} action="Valider">
        {confirm => (
          <IconButton 
            aria-label="cancel"
            onClick={confirm(()=>this.props.handleCancel(tableMeta.rowData))}
            >
            <SettingsBackupRestoreIcon fontSize="large" />
          </IconButton>          
          )}
         </Confirm>
          );
        }
      }
    }, 
   ];
 
   const options = {

     search: true,
     searchOpen: !this.props.detailVersion,
     searchPlaceholder: "Rechercher par Désignation, Référence, Machine...",
     print: true,
     download: true,
     rowsPerPage: 60,
     rowsPerPageOptions: [30, 60, 90, 120],
     responsive: this.props.detailVersion ? 'vertical' : 'standard',
     tableBodyHeight: '100%',
     searchText: this.props.searchText,
     selectableRows: 'none',
     onCellClick: (a,meta) => {
      if(meta.colIndex!==12){
        this.props.onRowClick(this.props.data[meta.dataIndex].psID)
      }
     },
     onSearchChange: (searchText) => {
      this.props.onSearchTextChange(searchText)
     },
     textLabels: {
      body: {
        noMatch: "Aucune entrée à afficher",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Articles par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Pièces(s) sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      }
    }
  })
   
  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Historique des entrées et sorties du magasin"}
    data={this.props.data}
    columns={columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
