import React, {useState, useEffect} from 'react';
import Creatable from 'react-select/creatable';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import axios from 'axios';


export default function SelectModule(props) {

  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [isLoading, setIsLoadding] = useState(false)

  useEffect(() => {
    if(props.update){
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/${props.from}`, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        const ops = res.data.map((el) => {
          const obj = {}
          obj["value"]= el.listID
          obj["label"]= el.name
        return obj
        })
        setOptions(ops)
      })
    }
  }, [props.update]);

  const formatCreateLabel = inputValue => (
    <span style={{ fontWeight: "bold" }}>Créer une nouvelle commande: {inputValue}</span>
  );

  const createOption = (item) => ({
    value: item.listID,
    label: item.name,
  });

  const handleCreate = (inputValue) => {
    setIsLoadding(true)
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_lists`, {name: inputValue.charAt(0).toUpperCase() + inputValue.slice(1), date: new Date()}, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        const newOption = createOption(res.data);
        setOptions(options => [...options, newOption])
        props.onChange(newOption)
        setIsLoadding(false)
      })
  };

  useEffect(() => {
      setSelectedOption([props.selectedOption])
  }, [props.selectedOption]);

    return (
      <FormGroup style={{width: "100%", marginRight: "10px", marginTop: "20px"}}>
      <InputLabel style={{marginBottom: "8px"}}>{props.title}</InputLabel>
      <Creatable
        value={options.filter((el)=>{
          return selectedOption.includes(el.value)
        })}
        autoFocus
        onChange={props.onChange}
        onCreateOption={handleCreate}
        options={options}
        isDisabled={isLoading}
        menuPosition="fixed"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        formatCreateLabel={formatCreateLabel}
        placeholder="Rechercher ou créer une nouvelle liste..."
      />
      </FormGroup>
    );
}