import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        flexGrow: 1,
     },
     buttonAdd:{
        marginRight: 10
    }

  }));

export default function Toolbar(props) {
    const classes = useStyles();
    return(
    <div className={classes.toolBar}>
        <h2>{props.name}</h2>
        <div>
            <Button variant="contained" onClick={props.handleOpenDoc} color="primary" className={classes.buttonAdd} title="Télecharger la commande">
                    <DescriptionIcon/> 
            </Button>
            <Button variant="contained" onClick={props.handleOpenDocPrice} className={classes.buttonAdd} title="Télecharger la commande avec les prix">
                    <LocalAtmIcon/> 
            </Button>
        </div>
    </div>
    )
}