import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import SelectModule from './select'
import NumericInput from 'react-numeric-input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function CommandDialog(props) {

   const handleChange = (value, a, target) => {
    const newData = [...props.panier]
    newData[target.name].qte = value
    props.onChangePanier(newData)
  };

  const handleDelete = (id) => {
    const newData = [...props.panier]    
    newData.splice(id, 1)
    props.onChangePanier(newData)
  };

    return (
        <div>
        <Dialog open={props.dialogState} onClose={props.handleClose} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogContent>
            <Typography variant="h6" gutterBottom>Commander {props.panier.length} pièce(s) de rechange</Typography>
            <SelectModule 
              title="Selectionner une liste de commande"
              onChange={props.handleSelectList} 
              selectedOption={props.selectedList} 
              update={props.dialogState}
              from="command_list"
            /> 
        <EditCommandes data={props.panier} handleChange={handleChange} handleDelete={handleDelete}/>
        </DialogContent>

        <DialogActions>
        <Button onClick={props.handleCancel} color="primary" style={{position: "absolute", left:"10px"}}>
            Annuler la commande
        </Button>
        <Button onClick={props.handleClose} color="primary">
            Retour
        </Button>
        <Button onClick={props.handleSubmit} color="primary" disabled={!props.selectedList || props.offline || props.panier.length===0}>
            Commander
        </Button>

         </DialogActions>
        </Dialog>
        </div>
    )
}


function EditCommandes(props) {

  return (
    <List style={{marginTop: "1rem", margin: "auto"}}>
      {props.data.map((value, i)=> {
        const labelId = `checkbox-list-label-${i}`;
        const inputId = `input-list-label-${i}`;
        const listKey = `listKey-${i}`;
        const secondary = `Ref. ${value.code} - Quantité: ${value.magasin} ${value.unit} - Conso. estimée: ${value.annuelle} ${value.unit}`

        return (
          <ListItem key={listKey}>
          <IconButton id={i} size="small" title="supprimer" aria-label="delete" style={{color: "red", marginRight: "1rem"}} onClick={()=>props.handleDelete(i)} >
              <HighlightOffIcon />
          </IconButton>
            <ListItemText 
                id={labelId} 
                primary={value.name} 
                secondary = {secondary}
                style={{maxWidth: "72%", wordWrap: "break-word"}}/>

            <ListItemSecondaryAction>
              <NumericInput 
                id={inputId}
                name={i}
                required
                step={value.unit==="ML"? 0.5:1} 
                precision={value.unit==="ML"? 2:0} 
                value={value.qte}
                min={1}
                style={{
                  input:{
                    fontSize: "1.2rem",
                    width: "7rem",
                  },
                  }}
                onChange={props.handleChange}
            />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>

  );
}