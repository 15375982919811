import React, {useState, useRef, useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Redirect, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl';
import logo from './icon.png'


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const passInput = useRef(null);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [values, setValues] = useState({
    account: 'bureau_etude',
    password: ""
  });
  const [accounts, setAccounts] = useState([])
  const [isLoggedIn, setLoggedIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { defaultAcc } = useParams();
//${window.location.port}
  const getAccounts = () =>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/accounts`)
    .then(res =>{
      setAccounts(res.data)
    }).catch(e => {
        enqueueSnackbar('Serveur indisponible', {variant:"error"});
    });
  }

  const handleChange = (event) => {
    const {name, value} = event.target

    if(name==='account'){
        setValues({...values, account: value, password: ""})
        setTimeout(() => {
          passInput.current.focus();
        }, 100);

    }else{
      setValues({...values, password: value})
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`, values).then(result => {
      if (result.status === 200) {
        localStorage.setItem("token", result.data);
        localStorage.setItem('account', values.account)
        setLoggedIn(true);
      }
    }).catch(e => {
      if(e.response){        
        enqueueSnackbar(e.response.data, {variant:"error"});  
  
      } else {
        enqueueSnackbar('Serveur indisponible', {variant:"error"});
      }
    });
  }

  useEffect(() => {
    getAccounts()
    setValues({...values, account: defaultAcc})
    
    setLabelWidth(inputLabel.current.offsetWidth);
    var interval_id = window.setInterval("", 9999); // Get a reference to the last
                                                // interval +1
    for (var i = 1; i < interval_id; i++)
            window.clearInterval(i);

  }, []);

  const linkStyles = {
    color: 'inherit',
    textDecoration: 'inherit'
  }
  
  if (isLoggedIn) {
      return <Redirect to="/" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo}/>
        <Typography component="h1" variant="h4">
          Gestion Des Stocks 
        </Typography>
        <Typography component="h6" variant="h6">
          Département Maintenance
        </Typography>
        <br/><br/>
        <form className={classes.form} noValidate>
        <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-zone-simple">
          Sélectionez un compte
        </InputLabel>
        <Select
          value={values.account}
          name="account"
          labelWidth={labelWidth}
          inputProps={{
            name: 'account',
            id: 'outlined-age-simple',
          }}
          onChange={handleChange}
        >
            {accounts.map((account)=>{
              if(account.name!=""){
                return(
                    <MenuItem id={account.userID} value={account.account}>{account.name}</MenuItem>
                    )
              }
            })}
        </Select>
      </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus={defaultAcc}
              value={values.password}
              onChange={handleChange}
              name="password"
              label="Mot de passe"
              type="password"
              inputRef={passInput}
            />          

          <Button
            disabled={values.password==="" || values.account===""}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Accéder
          </Button>
        </form>
      </div>
    </Container>
  );
}