import axios from 'axios'

export function handleData (data){
    const newData = data.map((el)=>{
        let color = 0
        if(el.qte<=0) color =  2
        else if(el.qte<=el.alerte) color =  1
        el["color"] = color
        el["currency"] = el.currency==="E"?"€":"DZD"
        el["type"] = el.type===0?"Standard":"Importante"
        if(el.machine) el["machine"] =  el.machine.name
        if(el.fournisseur) el["fournisseur"] =  el.fournisseur.name
        el["count"] = 0
        if(el.postav){
          const postav = el.postav.split(";")
          el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
        }
        return el
      })
    return newData
}

export const mergeID = (arr1,arr2) => {
    let merge = [];
    for(let i=0; i<arr1.length; i++) {
      merge.push({
       ...arr1[i], 
       ...(arr2.find((itmInner) => itmInner.psID === arr1[i].psID))}
      );
    }
    return merge.map((el)=>{
        el["count"] = el.unit == "ML" ? el.count.toFixed(2) : el.count
        return el
      });
  }


export const getData = async (url) => {
    return axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/pieces`, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
        return res.data
    })
}

export const getQtes = async (url) => {
    return axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/history_count`)
    .then(res => {
        return res.data
    })
}

