import React, { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Confirm from '../confirmDialog'
import EditDialog from './editDialog'
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  constructor(){
    super()
    this.state = {
      dialogState: false,
      onEditState: false,
  }
  this.handleCloseDialog = this.handleCloseDialog.bind(this)
  this.onEdit = this.onEdit.bind(this)
  this.handleClickProformat = this.handleClickProformat.bind(this)
  this.handleClickEdit = this.handleClickEdit.bind(this)
}

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleClickDelete = () => {
    const index = this.props.selectedRows.data.map(item => item.dataIndex)
    const objects = this.props.displayData.filter((item) => {
      return index.includes(item.dataIndex); 
    });

    const comIDS = objects.map((item)=>{
        return item.data[0]
    })

    const psIDS = objects.map((item)=>{
      return item.data[1]
  })

    this.props.onDelete({
      comIDS,
      psIDS
    })
    this.props.setSelectedRows([])
  };

  handleClickRecu = () => {
    const index = this.props.selectedRows.data.map(item => item.dataIndex)
    const objects = this.props.displayData.filter((item) => {
      return index.includes(item.dataIndex); 
    });

    let update = objects.map((item)=>{
        const data = {
          name: "updated",
          psID: item.data[1],
          qte: item.data[11] + item.data[14],
          newQte : item.data[11] + item.data[14],
          price: item.data[16],
          state: item.data[18],
          status: 0,
          action: 2,
          date: new Date(),
          delta: item.data[14],
          comment: "Commande: " + this.props.command
        }
        return data
    })

    update = update.filter((el)=>{
      return el.state!=3
    })

    const comID = objects.map((item)=>{
      return item.data[0]
  })
    this.props.onRecu({
      comID,
      update
    })
    this.props.setSelectedRows([])
  };

  handleClickEdit = () => {
    this.setState({
      dialogState: true,
      onEditState: true,
    })
  }
  handleClickProformat = () => {
    this.setState({
      dialogState: true,
      onEditState: false,
    })
  }

  handleCloseDialog = () => {
    this.setState({
      dialogState: false,
      onEdit: false,
      onProformat: false
    })
    this.props.setSelectedRows([]);
  };

  onEdit = (data) => {
    this.props.onEdit(data)
    this.props.setSelectedRows([])
  }

  onProformat = (data) => {
    let update = data.map((item)=>{
      const data = {
        psID: item["psID"],
        comID: item["comID"],
        price: item["price"],
        state: item["state"] != 3 ? 2 : 3,
        name: "updated",
      }
      return data
  })
    update = update.filter((el)=>{
      return el.state!=3
    })

    this.props.onProformat(update)
    this.props.setSelectedRows([])
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>        
        
      <Confirm title={`Etes-vous sûr de vouloir valider cet article ?`} action="Valider">
      {confirm => (
          <Tooltip title={"Commande proformat validée"}>
            <Button
              variant="outlined"
              style={{color: "#2980b9", fontWeight: "bolder", marginRight: "0.5rem"}}
              color="primary"
              onClick={this.handleClickProformat}
              startIcon={<DoneIcon />}
            >
              Proformat validée
            </Button>
          </Tooltip>
        )}
        </Confirm>
        
      <Confirm title={`Etes-vous sûr de vouloir valider la recéption de cet article ?`} action="valider">
      {confirm => (
          <Tooltip title={"Pièce reçue au magasin (ajouter la quantité commandée)"}>
            <Button
              variant="outlined"
              style={{color: "#27ae60", fontWeight: "bolder", marginRight: "0.5rem"}}
              onClick={confirm(()=>this.handleClickRecu())}
              startIcon={<DoneAllIcon />}
            >
              Pièce reçue
            </Button>
          </Tooltip>
        )}
        </Confirm>

      <Confirm title={`Etes-vous sûr de vouloir supprimer cet article ?`} action="Suprimer">
      {confirm => (
        <Tooltip title={"Suprimer la commande"}>
          <Button
            variant="outlined"
            style={{fontWeight: "bolder", color:"red"}}
            onClick={confirm(()=>this.handleClickDelete())}
            startIcon={<DeleteIcon />}
            color="secondary"
          >
            Supprimer
          </Button>
        </Tooltip>
      )}
      </Confirm>

      <Tooltip title={"Modifier la quantité et ajouter un commentaire"}>
        <Button
          variant="outlined"
          style={{fontWeight: "bolder", color:"orange", marginLeft: "0.5rem"}}
          onClick={this.handleClickEdit}
          startIcon={<EditIcon />}
          color="secondary"
        >
          Editer
        </Button>
      </Tooltip>
    
      <EditDialog
        dialogState={this.state.dialogState}
        onEditState={this.state.onEditState}
        onProformatState={this.state.onProformatState}
        handleClose={this.handleCloseDialog}
        onEdit = {this.onEdit}
        onProformat = {this.onProformat}
        selectedItems={this.props.selectedRows}
        displayData = {this.props.displayData}
      />   
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);