import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Confirm from '../confirmDialog'
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleClickArchive = () => {
    const id = this.props.displayData.filter((item) => {
      return item.dataIndex===this.props.selectedRows.data[0].dataIndex;
    });

    const listID = id[0].data[0]

    this.props.onListArchive(listID)
    this.props.setSelectedRows([])
  };

  handleClickEdit = () => {
    const row = this.props.displayData.filter((item) => {
      return item.dataIndex===this.props.selectedRows.data[0].dataIndex;
    });

    this.props.onEditSubmit(row["0"].data)
    this.props.setSelectedRows([])
  };

  handleClickDelete = () => {
    const id = this.props.displayData.filter((item) => {
      return item.dataIndex===this.props.selectedRows.data[0].dataIndex;
    });

    const listID = id[0].data[0]

    this.props.onListDelete(listID)
    this.props.setSelectedRows([])
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Confirm title={`Etes-vous sûr de vouloir archiver cette commande ?`} action="Archiver">
          {confirm => (
          <Tooltip title={"Valider et archiver"}>
            <Button
              variant="outlined"
              style={{fontWeight: "bolder", marginRight: "0.5rem"}}
              onClick={confirm(()=>this.handleClickArchive())}
              startIcon={<ArchiveIcon />}
            >
              Désarchiver
            </Button>
            </Tooltip>
            )}
        </Confirm>
        
        <Confirm title={`Etes-vous sûr de vouloir supprimer cette commande ?`} action="Suprimer">
        {confirm => (
        <Tooltip title={"Suprimer la commande"}>
          <Button
            variant="outlined"
            style={{fontWeight: "bolder", color:"red"}}
            onClick={confirm(()=>this.handleClickDelete())}
            startIcon={<DeleteIcon />}
            color="secondary"
          >
            Supprimer
          </Button>
          </Tooltip>
          )}
          </Confirm>

          <Tooltip title={"Modifier la quantité et ajouter un commentaire"}>
          <Button
            variant="outlined"
            style={{fontWeight: "bolder", color:"orange", marginLeft: "0.5rem"}}
            onClick={this.handleClickEdit}
            startIcon={<EditIcon />}
            color="secondary"
          >
            Editer
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);