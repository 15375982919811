import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class ConfirmDialog extends Component {
  state = {
    open: false,
    callback: null
  }

  show = callback => event => {
    event.preventDefault()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value }
    }

    this.setState({
      open: true,
      callback: () => callback(event)
    })
  }

  hide = () => this.setState({ open: false, callback: null })

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    return (
    <React.Fragment>
        {this.props.children(this.show)}
        <Dialog
            open={this.state.open}
            onClose={this.hide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogActions>
            <Button onClick={this.hide} color="primary">
                Annuler
            </Button>
            <Button onClick={this.confirm} color="primary" autoFocus>
                {this.props.action}
            </Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>
    )
  }
}