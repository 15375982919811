import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

export default function AddDialog(props) {
  const initialState = {
    id: '',
    name: '',
    code: '',
   }
  
  const handleChange = (event) => {
    const {name, value} = event.target
    setData({...data, [name]: value})
  }

  const handleClose = () => {
    props.handleClose()
    setData(initialState)
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    props.onFormSubmit(data)
    props.handleClose()
    setData(initialState)
  }

  const handleEdit = (event) => {
    event.preventDefault()
    props.onFormEdit(data)
    props.handleClose()
    setData(initialState)
  }

  useEffect(() => {
     setDialogState(props.dialogState)
   }, [props.dialogState])

   useEffect(() => {
    if(props.isEdit){
        setData({
        id: props.editPs[0],
        name: props.editPs[1],
        code: props.editPs[2],
      })
    }
   }, [props.isEdit])

   const [dialogState, setDialogState] = useState(props.dialogState)

   const [data, setData] = useState(initialState)

   const {id, name, code} = data

   const {isEdit} = props

   return (
    <div>
      <Dialog 
          open={dialogState} 
          onClose={handleClose} 
          fullWidth 
          aria-labelledby="form-dialog-title"
         >
        <DialogContent style={{marginBottom: "2rem"}}>
        {isEdit?
            <Typography variant="h6" gutterBottom >Editer la machine {name}</Typography>:
            <Typography variant="h6" gutterBottom >Ajouter une nouvelle machine</Typography>
          }

          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Nom de la machine"
            name="name"
            value={name}
            onChange={handleChange}
            fullWidth
          />
         <TextField
            margin="dense"
            id="code"
            label="Code de la machine"
            name="code"
            value={code}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>

          {isEdit? 
            <Button onClick={handleEdit} color="primary" disabled={data.name === ""}>
            Editer
          </Button> :
            <Button onClick={handleSubmit} color="primary" disabled={data.name === ""}>
            Ajouter
          </Button>
          }  

          </DialogActions>
      </Dialog>
    </div>
  );
}