import React, {useState, Fragment} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FeedbackIcon from '@material-ui/icons/Feedback';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import HistoryIcon from '@material-ui/icons/History';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AirplayIcon from '@material-ui/icons/Airplay';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import {Link, Redirect} from 'react-router-dom'
import Decode from 'jwt-decode'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios'

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  bar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: "space-between",
  },
  bar1: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  menus: {
    paddingTop: theme.spacing(4),
  }
}));

const linkStyles = {
    color: 'inherit',
    textDecoration: 'inherit'
}

export default function DrawerApp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false)

  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const raw_token = localStorage.getItem("token")
  let token 
  if(raw_token) token = Decode(raw_token)

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    setRedirect(true)
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const getDatabase = () => {
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/dbps.db`, {headers: {"auth-token" : localStorage.getItem('token')}, responseType: 'blob'})
    .then(res => {
      const date = new Date()
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'dbps '+("0" + (date.getDate())).slice(-2)+("0" + (date.getMonth() + 1)).slice(-2)+date.getFullYear()+'.db');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  if (redirect) {
      return <Redirect to="/login" />;      
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.bar}>
        <div className={classes.bar1}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            Gestion des stocks Maintenance
           </Typography>
          </div>
            <IconButton onClick={logOut} title='Se déconnecter'>
                <ExitToAppIcon style={{color:"white"}}/>                    
            </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={mobile ? "temporary" : "permanent"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.menus} onClick={handleDrawerClose}>
            <Link to={`/`} style={linkStyles}>
                <ListItem button key='list_complete'>
                    <ListItemIcon title="Liste des pièces de rechange">
                        <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Liste des pièces de rechange' />
                </ListItem>            
            </Link>
            {token.history &&
            <Link to={`/historique`} style={linkStyles}>
            <ListItem button key='historique'>
                <ListItemIcon title="Historique des E/S">
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary='Historique des E/S' />
            </ListItem>  
          </Link>
            }
          {token.isAdmin &&
          <Link to={`/stock_mort`} style={linkStyles}>
                <ListItem button key='stock_mort'>
                    <ListItemIcon title="Stock mort">
                        <SentimentVeryDissatisfiedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Stock mort' />
                </ListItem>            
            </Link>
          }
      </List>

      {token.commands &&
        <Divider />
      }
        <List onClick={handleDrawerClose}>
        {token.isAdmin &&
        <Link to={`/commander`} style={linkStyles}>
            <ListItem button key='commander_une_piece'>
                <ListItemIcon title='Commander des pièces'>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary='Commander des pièces' />
            </ListItem>  
        </Link>
        }
        {token.commands &&
        <Fragment>
          <Link to={`/commandes`} style={linkStyles}>
              <ListItem button key='liste_des_commandes'>
                  <ListItemIcon title='Commandes en cours'>
                      <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText primary='Commandes en cours' />
              </ListItem>  
            </Link>
            <Link to={`/hist_commandes`} style={linkStyles}>
              <ListItem button key='hist_commandes'>
                  <ListItemIcon title='Historique des commandes'>
                      <RestorePageIcon />
                  </ListItemIcon>
                  <ListItemText primary='Historique des commandes' />
              </ListItem>  
            </Link>
          </Fragment>
        }
        </List>

        {token.isAdmin &&
        <Fragment>
        <Divider />
        <List onClick={handleDrawerClose}>
        <Link to={`/machines`} style={linkStyles}>
                <ListItem button key='machines'>
                    <ListItemIcon title='Machines / Equipement'>
                        <AirplayIcon />
                    </ListItemIcon>
                    <ListItemText primary='Machines / Equipement' />
                </ListItem>
            </Link>   
        <Link to={`/fournisseurs`} style={linkStyles}>
            <ListItem button key='fournisseurs'>
                <ListItemIcon title='Fournisseurs'>
                    <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary='Fournisseurs' />
            </ListItem>
        </Link> 
        </List>        
        <Divider />
        <List>
            <ListItem button key='downloadDb' onClick={getDatabase}>
                  <ListItemIcon title='Télécharger la base de données'>
                      <GetAppIcon />
                  </ListItemIcon>
                  <ListItemText primary='Télécharger la base de données' />
            </ListItem> 
        </List>
        </Fragment>
      }
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>{props.children}</div>
      </main>
    </div>
  );
}