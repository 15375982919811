import React, { Component , Fragment } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Confirm from '../confirmDialog'

class Table extends Component {
  constructor(props){
    super()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.data !== nextProps.data ? true : false;
  }

  render(){
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "name",
      label: "Nom de la pièce",
      options: {
       filter: true,
       sort: true,
       searchable: true,
      }
     },
     {
      name: "code",
      label: "Code de la machine",
      options: {
       filter: true,
       sort: true,
      }
     },
      {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
        setCellProps: () => ({style:{textAlign: 'center'}}),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
        <IconButton 
          aria-label="editButton"
          onClick={() => {
            this.props.handleEdit(tableMeta.rowData)
          }}
          >
          <EditIcon/>
        </IconButton>

        <Confirm title={`Etes-vous sûr de vouloir supprimer cette machine ?`} action="Supprimer">
        {confirm => (
          <IconButton 
            aria-label="delete"
            onClick={confirm(()=>this.props.handleDelete(tableMeta.rowData[0]))}
            >
            <DeleteOutlineIcon />
          </IconButton>          
          )}
         </Confirm>
         </div>
          );
        }
      }
    }, 
   ];
 
   const options = {

     search: true,
     searchOpen: true,
     filter: false,
     searchPlaceholder: "Rechercher par Désignation ou Code...",
     print: true,
     download: true,
     rowsPerPage: 60,
     rowsPerPageOptions: [60, 90, 120],
     responsive: 'scrollFullHeight',
     searchText: this.props.searchText,
     selectableRows: 'none',
     onSearchChange: (searchText) => {
      this.props.onSearchTextChange(searchText)
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Articles par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
    },
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      }
    }
  })
   
  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Liste des machines"}
    data={this.props.data}
    columns={columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
