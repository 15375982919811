import React, {useState, useEffect} from 'react';
import Table from './table'
import Toolbar from './toolbar'
import AddDialog from './addDialog'
import { useSnackbar } from 'notistack';
import axios from 'axios'
import * as moment from 'moment-timezone'
import "moment/locale/fr";

moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

function Fournisseurs() {

  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState("")
  const [editPs, setEditPs] = useState({})
  const [dialogState, setDialogState] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    getData()
  }, [])

  const onSearchTextChange =  (text) => {
    setSearchText(text)
  }

  const getData = ()=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/fournisseurs`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {      
          setData(res.data)
          document.getElementsByClassName("MuiInputBase-input MuiInput-input")[0].focus()
        }).catch(err=>{
          enqueueSnackbar('Serveur indisponible', {variant:"error"});
        }) 
  }

  const handleOpenDialog = (editPs) => {
    const isEdit = editPs[1]? true : false
    setDialogState(true)
    setEditPs(editPs)
    setIsEdit(isEdit)
  };

  const handleCloseDialog = () => {
    setDialogState(false)
    setEditPs(null)
    setIsEdit(false)
  };

  const onFormSubmit = (data) =>{
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/fournisseurs`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Fournisseur ajouté avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const onFormEdit = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/fournisseurs/${data.id}`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Fournisseur edité avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const onDelete = (data) =>{
    axios.delete(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/fournisseurs/${data}`, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Fournisseur supprimé avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }
     
  return (
    <React.Fragment>
        <div>
          <Toolbar
            handleOpen={handleOpenDialog}
          />
          <AddDialog
            dialogState={dialogState}
            handleClose={handleCloseDialog}
            onFormSubmit={onFormSubmit}
            onFormEdit={onFormEdit}
            editPs={editPs}
            isEdit={isEdit}
            />
          <Table
            data={data}
            handleEdit={handleOpenDialog}
            handleDelete={onDelete}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            />
        </div>
    </React.Fragment>
  );
}

export default Fournisseurs;
