import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar'
import DetailDialog from './../list_pieces/detailDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios'
import Decode from 'jwt-decode'
import { useSnackbar } from 'notistack';
import * as moment from 'moment-timezone'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import "moment/locale/fr";
moment.locale("fr");
moment.tz.setDefault("Africa/Algiers");

function History() {

  const [data, setData] = useState([])
  const [filtredData, setFiltredData] = useState([])
  const [entrees, setEntrees] = useState(true);
  const [sorties, setSorties] = useState(true);
  const [corrections, setCorrections] = useState(true);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [selectedPS, setselectedPS] = useState(0)
  const [showDetail, setShowDetail] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [loadded, setLoadded] = useState(true)
  const { enqueueSnackbar } = useSnackbar();

  const raw_token = localStorage.getItem("token")
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  let token
  if(raw_token) token = Decode(raw_token)  

  useEffect(()=>{
    getData(startDate, endDate)
    window.addEventListener('hashchange', handleOnHashChange);
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal"){
      onCloseDetail()
    }
  }; 

  const onSearchTextChange =  (text) => {
    setSearchText(text)
  }

  function filterData(tableData, filters) {
    return tableData.filter(item => filters.includes(item.action));
  }

  useEffect(()=>{
    let filt = []
    if(entrees) filt.push(1,2,4)
    if(sorties) filt.push(0)
    if(corrections) filt.push(3)

    setFiltredData(filterData(data, filt))
  }, [data, entrees, sorties, corrections])

  const handleChange = (event) => {
    if(event.target.name==="entrees") setEntrees(event.target.checked)
    else if(event.target.name==="sorties") setSorties(event.target.checked)
    else if(event.target.name==="corrections") setCorrections(event.target.checked)
  }

  const handleChangeStart = (event) => {
    let start, end
    if(event.isAfter(endDate)){
      start = endDate
      end = event.toDate()
    }else{
      start = event.toDate()
      end = endDate
    }
    setStartDate(start)
    setEndDate(end)
    getData(start, end)
  }

  const handleChangeEnd = (event) => {
    let start, end
    if(event.isBefore(startDate)){
      start = event.toDate()
      end = startDate
    }else{
      start = startDate
      end = event.toDate()
    }
    setStartDate(start)
    setEndDate(end)
    getData(start, end)
  }
  
  const getData = (start, end)=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/historiques`, {headers: {"auth-token" : localStorage.getItem('token')}, params: {startDate: start, endDate: moment(end).add(1, 'days').toDate()}})
        .then(res => {      
          let data = res.data.map((el)=>{ 
            el['psID'] = el.psID
            if(el.piece.machine) el['machine'] = el.piece.machine.name
            if(el.piece.fournisseur) el['fournisseur'] = el.piece.fournisseur.name
            el['codeMagasin'] = el.piece.codeMagasin ? el.piece.codeMagasin : ""
            el['zone'] = el.piece.zone
            el['delta'] = el.delta +" "+ el.piece.unit
            el['newQte'] = el.newQte +" "+ el.piece.unit
            el['piece'] = el.piece.name
            el['comment'] = el.comment ? el.comment : ""
            el['date'] = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSS ZZ').format("DD MMMM YYYY")

            return el
          })
          setData(data)
          document.getElementsByClassName("MuiInputBase-input MuiInput-input")[0].focus()
          setLoadded(false)
        }).catch(err=>{
          enqueueSnackbar('Serveur indisponible', {variant:"error"});
          setLoadded(false)
        }) 
  }

  const handleCancel = (data) => {
    const qte = parseFloat(data[8].split(" ")[0])
    
    const send = {
      qte: Math.abs(qte),
      psID: data[2],
      historyID: data[1]
    }
    
    if(data[0]===0 || data[0]===3 && qte < 0){
      axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/history_inc`, send, {headers: {"auth-token" : localStorage.getItem('token')}, send})
      .then(res => {
        enqueueSnackbar('Opération annulée', {variant:"warning"});
        getData(startDate, endDate)
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
    }else if(data[0]===1 || data[0]===2 || data[0]===4 || data[0]===3 && qte > 0){
      axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/history_dec`, send, {headers: {"auth-token" : localStorage.getItem('token')}})
      .then(res => {
        enqueueSnackbar('Opération annulée', {variant:"warning"});
        getData(startDate, endDate)
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
    }
  }

  const onRowClick = (row) => {
    setselectedPS(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedPS(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
    }

  function groupBy(xs, f) {
      return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }
  
  const handleOpenDoc = () => {
    let d
    d = groupBy(data, (c) => c.action);

    d["Sorties"] = d["0"]
    d["Entrées"] = d["2"]
    d["Retours"] = d["1"]
    d["Nouveaux"] = d["4"]
    d["Corrections"] = d["3"]
    delete d["0"]
    delete d["2"]
    delete d["1"]
    delete d["4"]
    delete d["3"]

    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/historiqueDoc`,{
      date: moment().format("DD MMMM YYYY"),
      debut: moment(startDate).format("DD MMMM YYYY"),
      fin: moment(endDate).format("DD MMMM YYYY"),
      data: d
    }, {headers: {"auth-token" : localStorage.getItem('token')}}).then(res => {
      enqueueSnackbar('Chargement du doccument en cours...', {variant:"info"});
      window.location = '/docs/historique.docx';
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }
     
  return (
    <React.Fragment>
        <div style={{marginBottom: "2rem"}}>
        <Toolbar 
            sorties={sorties} 
            entrees={entrees} 
            corrections={corrections} 
            handleChange={handleChange}
            handleChangeStart={handleChangeStart}
            handleChangeEnd={handleChangeEnd}
            startDate={startDate}
            endDate={endDate}
            handleOpenDoc = {handleOpenDoc}
            />
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {token.psInfos}
            mobile = {fullScreen}
            isAdmin = {token.isAdmin}
        />
        {loadded &&
           <LinearProgress />   
        }
        <TablePieces 
            data={filtredData}
            handleCancel = {handleCancel}
            onRowClick = {onRowClick}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            token = {token}
            mobile = {fullScreen}
            />
        </div>
  
    </React.Fragment>
  );
}

export default History;
