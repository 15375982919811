import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar_list'
import Detail from './detail'
import AddDialog from './addDialog'
import { useSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import Decode from 'jwt-decode'
import axios from 'axios'

import * as moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

export default function Command_list() {

  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedList, setselectedList] = useState(0)
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [total, setTotal] = useState(0)
  const [editPs, setEditPs] = useState({})
  const [dialogState, setDialogState] = useState(false)
  const [loadded, setLoadded] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const { enqueueSnackbar } = useSnackbar();


  useEffect(()=>{
    getData()
    window.addEventListener('hashchange', handleOnHashChange);
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal" && window.location.hash !== "#detail"){
      onCloseDetail()
      handleCloseDialog()
    }
  }; 

  const raw_token = localStorage.getItem("token")
  let token
  if(raw_token) token = Decode(raw_token)  

  const onFormSubmit = (data) =>{
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_lists`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Commande crée avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const onFormEdit = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_lists/${data.listID}`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Commande editée avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const handleCloseDialog = () => {
    setDialogState(false)
    setEditPs(null)
    setIsEdit(false)
    window.history.pushState(null,null,'#');
  };

  const onListArchive = (data) =>{
        axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/archive_command/${data}`, {},{headers: {"auth-token" : localStorage.getItem('token')}})
          .then(res => {
            enqueueSnackbar('Commande archivée', {variant:"success"});
            getData()
          }).catch(err=>{
            enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
          })
  }

  const onListDelete = (data) =>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes/${data}`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const ids = res.data.commandes.map((item)=>{
            return item.piece.psID
          })
          return ids
        }).then((ids)=>{
          axios.delete(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_lists/${data}`, {headers: {"auth-token" : localStorage.getItem('token')}, data: ids })
          .then(res => {
            enqueueSnackbar('Commande suprimée avec succès', {variant:"warning"});
            getData()
          }).catch(err=>{
            enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
          })
        })
  }

  const onCommandDelete = (data) =>{
          axios.delete(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes`, {headers: {"auth-token" : localStorage.getItem('token')}, data})
          .then(res => {
              enqueueSnackbar('Pièce retirée de la commande', {variant:"warning"});
              getDetailData()
          }).catch(err=>{
            enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
          })
  }

  const onProformat = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/valid_proformat`, data, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
        enqueueSnackbar(data.length + ' Pièces validée(s)', {variant:"info"});
        getDetailData()
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
}

  const onRecu = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/piece_recu`, data, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
        enqueueSnackbar(data.update.length + ' Pièce(s) marquée(s) comme reçu(s)', {variant:"success"});
        getDetailData()
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }

  const onEdit = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/editCommand`, data, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
      enqueueSnackbar(data.length + ' Pièce(s) editée(s) avec succès', {variant:"success"});  
      getDetailData()
    }).catch(err=>{
      console.log(err)
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }

  const getData = ()=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_list`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const data = res.data.map((el)=>{
            let color = 0
            if(el.qte<=0) color =  2
            else if(el.qte<=el.alerte) color =  1
            el["color"] = color
            el["currency"] = el.currency==="E"?"€":"DZD"
            el["acommander"] = el.annuelle - el.qte 
            el["date_text"] = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSS ZZ').format("ddd DD MMMM YYYY")
            if(el.postav){
              const postav = el.postav.split(";")
              el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            return el
          })
          data.sort((a, b) => (b.color > a.color) ? 1 : -1)
          setData(data)
          setLoadded(false)
        }).catch(err=>{
          enqueueSnackbar('Serveur indisponible', {variant:"error"});
        }) 
  }

  function groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  const getDetailData = () => {
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/history_count`)
    .then(cnt =>{
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes/${selectedList}`, {headers: {"auth-token" : localStorage.getItem('token')}})
      .then(res => {
          setName(res.data.name)
          setDescription(res.data.description)
          let currency = "€"
          if(res.data.commandes[0]){
              currency = (res.data.commandes[0].piece.currency==="E"?"€":"DZD")
          }
          const data = res.data.commandes.map((el)=>{
              let item = {}
              item["comID"] = el.comID 
              item["psID"] = el.psID 
              item["listID"] = el.listID 
              item["code"] = el.piece.code ? el.piece.code : ""
              item["currency"] = el.piece.currency==="E"?"€":"DZD"
              item["codeMagasin"] = el.piece.codeMagasin 
              item["posMagasin"] = el.piece.posMagasin 
              item["annuelle"] = el.piece.annuelle
              if(el.piece.postav){
                  const postav = el.piece.postav.split(";")
                  item["postav"] = "TAB."+postav[0]+" POS."+postav[1]
              }else{
                item["postav"] = ""
              }
              item["piece"] = el.piece.name
              if(el.piece.machine){
                item["machineTitle"] =  el.piece.machine.name ? el.piece.machine.name + " " + (el.piece.machine.code ? el.piece.machine.code : "") : ""
                item["machine"] =  el.piece.machine.name ? el.piece.machine.name : " "
              }
              item["qte"] = el.qte
              item["oldQte"] = el.piece.qte
              item["unit"] = el.piece.unit
              item["price"] = el.price ? el.price.toFixed(2) : 0
              const montant = el.price * el.qte
              item["montant"] = montant.toFixed(2)
              item["state"] = el.state
              item["comment"] = el.piece.comment                
              return item
            })
            let merge = mergeID(data,cnt.data)
            merge = merge.map((el)=>{
              if(el.count){
                const count = el.unit == "ML" ? parseFloat(el.count.toFixed(2)) : el.count
                el["count"] = count
              }else{
                el["count"] = 0
              }
              return el
            })
            merge.sort((a, b) => (b.color > a.color) ? 1 : -1)
            setDetailData(merge)
            const subtotal = data.reduce((sum, i) => sum + parseFloat(i.montant), 0).toFixed(2);
            setTotal(subtotal + " " + currency)
      }).catch(err=>{
        enqueueSnackbar('Serveur indisponible', {variant:"error"});
      }) 
    }).catch(err=>{
      enqueueSnackbar('Serveur indisponible', {variant:"error"});
    }) 
}

  const onRowClick = (row) => {
    setselectedList(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedList(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
  }

  const handleOpenDialog = (editPs) => {
    const isEdit = editPs[1]? true : false
    setDialogState(true)
    setEditPs(editPs)
    setIsEdit(isEdit)
    window.location.assign("#modal")
  };

  const handleOpenDoc = () => {
    let data = detailData.map((el)=>{
      if(el.postav==="TAB. POS.") el["postav"] = ""
      el["machine"] = el.machineTitle
      
      return el
    })
    data = groupBy(data, (c) => c.machine);
    if(data.undefined){
      data["Autres"] = data.undefined
      delete data.undefined 
    } 
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/command`,{
      date: moment().format("DD MMMM YYYY"),
      title: name.replace(/[^a-zA-Z0-9 ]/g, ""),
      machines: data
    }, {headers: {"auth-token" : localStorage.getItem('token')}}).then(res => {
      enqueueSnackbar('Chargement du doccument en cours...', {variant:"info"});
      window.location = '/docs/'+name+'.docx';
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }

  const handleOpenDocPrice = () => {
    let data = detailData.map((el)=>{
      if(el.postav==="TAB. POS.") el["postav"] = ""
      el["machine"] = el.machineTitle      
      return el
    })
    data = groupBy(data, (c) => c.machine);
    if(data.undefined){
      data["Autres"] = data.undefined
      delete data.undefined 
    } 
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/commandPrice`,{
      date: moment().format("DD MMMM YYYY"),
      title: name.replace(/[^a-zA-Z0-9 ]/g, ""),
      description: description ? description : "",
      total: total,
      machines: data
    }, {headers: {"auth-token" : localStorage.getItem('token')}}).then(res => {
      enqueueSnackbar('Chargement du doccument en cours...', {variant:"info"});
      window.location = '/docs/'+name+'.docx';
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }

  const mergeID = (arr1,arr2) => {
    let merge = [];

    for(let i=0; i<arr1.length; i++) {
      merge.push({
       ...arr1[i], 
       ...(arr2.find((itmInner) => itmInner.psID === arr1[i].psID))}
      );
    }
    return merge;
  }
     
  return (
    <React.Fragment>
      {showDetail ? 
          <Detail //detail.js > table_command.js > CustomToolbarSelectCommand.js
              selectedList = {selectedList}
              onCloseDetail = {onCloseDetail}
              onDelete = {onCommandDelete}
              onProformat = {onProformat}
              onRecu = {onRecu}
              onEdit = {onEdit}
              getData = {getDetailData}
              data = {detailData}
              name = {name}
              total = {total}
              handleOpenDoc = {handleOpenDoc}
              handleOpenDocPrice = {handleOpenDocPrice}
              psInfos = {token.psInfos}
              isAdmin = {token.isAdmin}
            />
            :
      <div style={{marginBottom: "2rem"}}>
        <Toolbar
            handleOpen={handleOpenDialog}
            isAdmin = {token.isAdmin}
        />
        <AddDialog
            dialogState={dialogState}
            handleClose={handleCloseDialog}
            onFormSubmit={onFormSubmit}
            onFormEdit={onFormEdit}
            editPs={editPs}
            isEdit={isEdit}
            />
        {loadded &&
           <LinearProgress />   
        }  
        <TablePieces //table.js > CustomToolbarSelectList.js
            data={data}
            isAdmin = {token.isAdmin}
            onRowClick = {onRowClick}
            onListDelete = {onListDelete}
            onListArchive = {onListArchive}
            onEditSubmit = {handleOpenDialog}
            />
        </div>        
      }

    </React.Fragment>
  );
}