import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar'
import DetailDialog from './detailDialog'
import ChangeDialog from './changeDialog'
import AddDialog from './addDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';
import Decode from 'jwt-decode'
import axios from 'axios'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { handleData, mergeID, getData, getQtes} from './dataTools'

function Pieces() {
  const [data, setData] = useState([])
  const [changeState, setchangeState] = useState(false)
  const [changeData, setChangeData] = useState([])
  const [editPs, setEditPs] = useState({})
  const [dialogState, setDialogState] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [selectedPS, setselectedPS] = useState(0)
  const [bulkChange, setBulkChange] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [loadded, setLoadded] = useState(true)
  const [offline, setOffline] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const raw_token = localStorage.getItem("token")
  let token
  if(raw_token) token = Decode(raw_token)  

  useEffect(()=>{
    loadData()
    window.addEventListener('hashchange', handleOnHashChange);
    document.addEventListener("keydown", keyPress, false);
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const keyPress = (event)=> {
    if(event.keyCode === 113) {
      handleOpenDialog(null)
    }
  }

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal"){
      onCloseDetail()
      handleCloseChange()
      handleCloseDialog()
    }
  }; 

  const onSearchTextChange = (text) => {
    setSearchText(text)
  }

  const handleOpenChange = (editItem) =>{
    onSelectPs(editItem)
    setchangeState(true)
    setBulkChange(false)
    window.location.assign("#modal")
  }

  const handleOpenBulkChange = () =>{
    setchangeState(true)
    setBulkChange(true)
    window.location.assign("#modal")
  }

  const onSelectPs = (psID) =>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/piece/${psID}`, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
        setChangeData(res.data)
    })
  }

  const handleCloseChange = () =>{
    setBulkChange(false)
    setchangeState(false)
    setChangeData({})
  }

  const handleOpenDialog = (editPs) => {
    const isEdit = editPs[3]? true : false
    setDialogState(true)
    setEditPs(editPs)
    setIsEdit(isEdit)
    window.location.assign("#modal")
  };

  const handleCloseDialog = () => {
    setDialogState(false)
    setEditPs(null)
    setIsEdit(false)
  };
 

  const onFormSubmit = (d) =>{
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/pieces`, d, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {        
          let newData = [...data]
          newData = newData.concat(handleData([res.data]))
          newData = newData.sort((a, b) => b.color - a.color || b.psID - a.psID)
          setData(newData)  
          setOffline(false)
          enqueueSnackbar('Pièce ajoutée avec succès', {variant:"success"});
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
        setOffline(true)
      })
  }

  const onFormEdit = (d) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/pieces/${d.psID}`, d, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
          let newData = [...data]
          const index = data.findIndex(x => x.psID ===d.psID);
          newData[index] = handleData([res.data])[0]
          newData[index].count = d.count
          newData = newData.sort((a, b) => b.color - a.color || b.psID - a.psID)
          setData(newData)
          setOffline(false) 
        enqueueSnackbar('Pièce editée avec succès', {variant:"success"});
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
        setOffline(true)
      })
  }

  const onChangeSubmit = async(d) =>{
    let newData = [...data]
    let color = 0
    try {
      const index = data.findIndex(x => x.psID ===d.psID);
      const item = newData[index] 
        item.qte = (await axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/historiques/${d.psID}`, d, {headers: {"auth-token" : localStorage.getItem('token')} })).data.qte
        item.count = (await axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/ps_count/${d.psID}/12`)).data[0].count || 0     
        if(item.qte<=0) color =  2
        else if(item.qte<=item.alerte) color =  1
        item.color = color
        if(item.unit == "ML") item.count = item.count.toFixed(2)
      newData[index] = item
      newData = newData.sort((a, b) => (b.color - a.color) || (b.psID - a.psID))
      setData(newData)
      setOffline(false) 
      if(!bulkChange){
        try{
          document.getElementsByClassName("MuiInputBase-input MuiInput-input")[0].focus()
        }catch(e){
          return
        }
      }
      enqueueSnackbar('Quantité modifiée avec succès', {variant:"success"});
    }catch(e){
        enqueueSnackbar('Serveur indisponible', {variant:"error"});
        setOffline(true)
    }
}

  const onDelete = (d) =>{
    axios.delete(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/pieces/${d}`, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        if(res.status === 204){
          let newData = [...data]
          const index = data.findIndex(x => x.psID ===d);
          if (index !== -1) {
            newData.splice(index, 1);
            setData(newData)
            setOffline(false)
            enqueueSnackbar('Pièce suprimée avec succès', {variant:"warning"});
          }
        }
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
        setOffline(true)
      })
  }

  const loadData = async()=>{
    setData(JSON.parse(localStorage.getItem("data")) || [])
    let dataLoaded
    let qtes
    try {
       dataLoaded = await getData()
       dataLoaded = handleData(dataLoaded)
       qtes = await getQtes()
       dataLoaded = mergeID(dataLoaded, qtes)
       dataLoaded.sort((a, b) => (b.color > a.color) ? 1 : -1)
       setData(dataLoaded)
       localStorage.setItem("data", JSON.stringify(dataLoaded))
       setLoadded(false)
    }catch(e){
      if(e.response){        
        enqueueSnackbar(e.response.data, {variant:"error"}); 
        setOffline(false) 
  
      } else {
        enqueueSnackbar('Serveur indisponible', {variant:"error"});
        setLoadded(false)
        setOffline(true)
      }
    }
  }

  const onRowClick = (row) => {
    setselectedPS(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedPS(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
    }
     
  return (
    //ajouter un raccourci ctrl + n pour ajouter une piece
    <React.Fragment>
      <div style={{marginBottom: "2rem"}}>
        <Toolbar 
            handleOpen={handleOpenDialog} 
            handleOpenBulkChange={handleOpenBulkChange}
            token = {token}
            offline = {offline}
            />
        {loadded &&
           <LinearProgress />   
        }  
        <AddDialog
            dialogState={dialogState}
            handleClose={handleCloseDialog}
            onFormSubmit={onFormSubmit}
            onFormEdit={onFormEdit}
            onDelete={onDelete}
            editPs={editPs}
            isEdit={isEdit}
            onChangeSubmit={onChangeSubmit}
            mobile = {mobile}
            />
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {token.psInfos}
            mobile = {mobile}
            isAdmin = {token.isAdmin}
        />
        <TablePieces 
            data={data}
            handleOpen={handleOpenChange}
            handleEdit={handleOpenDialog}
            onRowClick={onRowClick}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            token = {token}
            psInfos = {token.psInfos}
            mobile = {mobile}
            loadded = {loadded}
            />
        <ChangeDialog
            dialogState={changeState}
            onClose={handleCloseChange}
            onChangeSubmit={onChangeSubmit}
            onSelectPs={onSelectPs}
            editData={changeData}
            data={data}
            bulkChange={bulkChange}
            mobile = {mobile}
            />
        </div> 
    </React.Fragment>
  );
}

export default Pieces;
