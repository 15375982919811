import React, { useState, useEffect} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TablePieces from './table_commande'
import Toolbar from './toolbar_commandes'
import DetailDialog from './../list_pieces/detailDialog'
import axios from 'axios'
import * as moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

export default function Detail(props) {
            
    const [data, setData] = useState([])
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [currency, setCurrency] = useState()
    const [showDetail, setShowDetail] = useState(false)
    const [selectedPS, setselectedPS] = useState(0)

    useEffect(()=>{
        axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes/${props.selectedList}`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
            setName(res.data.name)
            setDescription(res.data.description)
            if(res.data.commandes[0]){
                setCurrency(res.data.commandes[0].piece.currency==="E"?"€":"DZD")
            }
            const data = res.data.commandes.map((el)=>{
                let item = {}
                item["comID"] = el.comID 
                item["psID"] = el.psID 
                item["listID"] = el.listID 
                item["code"] = el.piece.code 
                item["codeMagasin"] = el.piece.codeMagasin
                item["posMagasin"] = el.piece.posMagasin  
                if(el.piece.postav){
                    const postav = el.piece.postav.split(";")
                    item["postav"] = "TAB."+postav[0]+" POS."+postav[1]
                }
                item["piece"] = el.piece.name
                if(el.piece.machine) item["machine"] =  el.piece.machine.name
                item["qte"] = el.qte
                item["unit"] = el.piece.unit
                item["price"] = el.piece.price
                const montant = el.piece.price * el.qte
                item["montant"] = montant.toFixed(2)
                item["state"] = el.piece.state
                item["comment"] = el.piece.comment                

                return item
              })
              data.sort((a, b) => (b.color > a.color) ? 1 : -1)
              setData(data)
        })
        window.addEventListener('hashchange', handleOnHashChange);
        return () => window.removeEventListener('hashchange', handleOnHashChange);
    }, [])

    const handleOnHashChange = () => {  
        if(window.location.hash !== "#detail"){
          onCloseDetail()
        }
      }; 

    const onRowClick = (row) => {
        setselectedPS(row)
        setShowDetail(true)
        window.location.assign("#detail")
      }

    const onCloseDetail = () => {
        setselectedPS(0)
        setShowDetail(false)
        window.location.replace('#modal')
    }

    return(
    <React.Fragment>
        <div style={{marginBottom: "2rem"}}>
        <ArrowBackIcon fontSize="large" style={{position: "absolute", top:"100px", cursor: "pointer"}} color="primary" onClick={()=>props.onCloseDetail()}/>   
        <div style={{marginLeft: "4rem", marginTop: "1.3rem"}}>
            <Toolbar
                name = {name}
                description = {description}
            />
        </div>
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {props.psInfos}
            mobile = {props.mobile}
            isAdmin = {props.isAdmin}
        />
          <TablePieces 
              data={data}
              currency={currency}
              onRowClick = {onRowClick}
              />
          </div>       
      </React.Fragment>
    )
}