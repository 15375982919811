import React, {useEffect, useState, Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import NumericInput from 'react-numeric-input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

export default function CommandDialog(props) {

    const handleChangeQte = (value, a, target) => {
      const newData = [...commande]
      newData[target.name].qte = value
      if(!value) newData[target.name].qte = 0
      setCommande(newData)
    };

    const handleChangeAnnu = (value, a, target) => {
      const newData = [...commande]
      newData[target.name].annuelle = value
      if(!value) newData[target.name].annuelle = 0
      setCommande(newData)
    };

    const handleChangePrice = (value, a, target) => {
        const newData = [...commande]
        newData[target.name].price = value
        if(!value) newData[target.name].price = 0
        setCommande(newData)
      };
    
    const handleSubmitEdit = (event) => {
        event.preventDefault()
        props.onEdit(commande)
        props.handleClose()
   }

   const handleSubmitProformat = (event) => {
    event.preventDefault()
    props.onProformat(commande)
    props.handleClose()
}

    const handleClose = () => {
        props.handleClose()
      }

    useEffect(() => {
        setDialogState(props.dialogState)
        if(props.dialogState === true){
          const selects = props.selectedItems.data.map(item => item.dataIndex)
          const displayData = props.displayData.filter((item) => {
            return selects.includes(item.dataIndex); 
          });
          const filtredData = displayData.map(item => {
            let ps = {}
            ps["psID"] = item.data[1]
            ps["comID"] = item.data[0]
            ps["name"] = item.data[6]
            ps["magasin"] = item.data[7]
            ps["qte"] = item.data[14]//
            ps["code"] = item.data[4]
            ps["price"] = item.data[16]//
            ps["currency"] = item.data[20]//
            ps["state"] = item.data[18]//
            ps["annuelle"] = item.data[13]//
            return ps
          })
          setCommande(filtredData)
         }
      }, [props.dialogState])

   const [dialogState, setDialogState] = useState(props.dialogState)
   const [commande, setCommande] = useState([])
   const {onEditState} = props

    return (
        <div>
        <Dialog open={dialogState} onClose={handleClose} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
        <DialogContent>
            {onEditState ?
              <Typography variant="h6" gutterBottom>Editer {commande.length} commande(s)</Typography>
              :
              <Typography variant="h6" gutterBottom>Veuillez actualiser les prix avant de valider:</Typography>
            }

        <EditCommandes 
              data={commande} 
              handleChangeQte={handleChangeQte} 
              handleChangePrice={handleChangePrice}
              handleChangeAnnu={handleChangeAnnu}
              onEditState={onEditState}
              />
        </DialogContent>

        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Annuler
        </Button>
        {onEditState ?
          <Button onClick={handleSubmitEdit} color="primary">
               Editer
          </Button>
          :
          <Button onClick={handleSubmitProformat} color="primary">
              Valider Proformat
          </Button>
        }
         </DialogActions>
        </Dialog>
        </div>
    )
}


function EditCommandes(props) {

  return (
    <Fragment>
      <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
      {props.onEditState &&
      <Fragment>
        <Typography variant="h6" style={{marginRight: "1.5rem"}}>Qté Estimée</Typography>
        <Typography variant="h6" style={{marginRight: "1.5rem"}}>Commande</Typography>
      </Fragment>
      }
          <Typography variant="h6" style={{marginRight: "1.5rem"}}>Prix unitaire</Typography>
      </div>

    <List style={{marginTop: "1rem", margin: "auto"}}>
      {props.data.map((value, i)=> {
        const labelId = `checkbox-list-label-${i}`;
        const inputId = `input-list-label-${i}`;
        const listKey = `listKey-${i}`;

        return (
          <ListItem key={listKey}>
            <ListItemText 
                id={labelId} 
                primary={(value.code ? value.code + ' - ' : "") + value.name} 
                style={{maxWidth: "82%"}}/>

            <ListItemSecondaryAction>
              {props.onEditState &&
              <NumericInput 
                  id={inputId+"estim"}
                  name={i}
                  required
                  step={value.unit==="ML"? 0.5:1} 
                  precision={value.unit==="ML"? 2:0} 
                  value={value.annuelle}
                  min={0}
                  style={{
                    input:{
                      fontSize: "1.2rem",
                      width: "8rem",
                      marginLeft: "2rem"
                    },
                    }}
                  onChange={props.handleChangeAnnu}
              />
              }
              {props.onEditState &&
                <NumericInput 
                  id={inputId+"qte"}
                  name={i}
                  required
                  step={value.unit==="ML"? 0.5:1} 
                  precision={value.unit==="ML"? 2:0} 
                  value={value.qte}
                  min={0}
                  style={{
                    input:{
                      fontSize: "1.2rem",
                      width: "6rem",
                      marginLeft: "1rem"
                    },
                    }}
                  onChange={props.handleChangeQte}
                  />
                  }
             <NumericInput 
                id={inputId+"price"}
                name={i}
                required
                step={1} 
                precision={2} 
                value={value.price}
                min={0}
                format={(num)=>num+' '+value.currency}
                style={{
                  input:{
                    fontSize: "1.2rem",
                    width: "8rem",
                    marginLeft: "1rem"
                  },
                  }}
                onChange={props.handleChangePrice}
            />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    </Fragment>
  );
}