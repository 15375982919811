import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar_list'
import Detail from './detail'
import Decode from 'jwt-decode'
import { useSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios'
import AddDialog from './../command_list/addDialog'

import * as moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

export default function Hist_commandes() {

  const [data, setData] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedList, setselectedList] = useState(0)
  const [editPs, setEditPs] = useState({})
  const [dialogState, setDialogState] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loadded, setLoadded] = useState(true)
  const { enqueueSnackbar } = useSnackbar();


  useEffect(()=>{
    getData()
    window.addEventListener('hashchange', handleOnHashChange);
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal" && window.location.hash !== "#detail"){
      onCloseDetail()
    }
  }; 

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const raw_token = localStorage.getItem("token")
  let token
  if(raw_token) token = Decode(raw_token)  

  const onListArchive = (data) =>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes/${data}`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const ids = res.data.commandes.map((item)=>{
            return item.piece.psID
          })
          return ids
        }).then((ids)=>{
          axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/desarchive_command/${data}`, ids, {headers: {"auth-token" : localStorage.getItem('token')}})
          .then(res => {
            enqueueSnackbar('Commande déarchivée', {variant:"success"});
            getData()
          }).catch(err=>{
            enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
          })
        })
  }

  const onListDelete = (data) =>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/commandes/${data}`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const ids = res.data.commandes.map((item)=>{
            return item.piece.psID
          })
          return ids
        }).then((ids)=>{
          axios.delete(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/hist_commandes/${data}`, {headers: {"auth-token" : localStorage.getItem('token')}, data: ids })
          .then(res => {
            enqueueSnackbar('Commande suprimée avec succès', {variant:"warning"});
            getData()
          }).catch(err=>{
            enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
          })
        })
  }

  const getData = ()=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/hist_commandes`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const data = res.data.map((el)=>{
            let color = 0
            if(el.qte<=0) color =  2
            else if(el.qte<=el.alerte) color =  1
            el["color"] = color
            el["currency"] = el.currency==="E"?"€":"DZD"
            el["acommander"] = el.annuelle - el.qte 
            el["date"] = moment(el.createdAt, 'YYYY-MM-DD HH:mm:ss.SSS ZZ').format("ddd DD MMMM YYYY")
            if(el.postav){
              const postav = el.postav.split(";")
              el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            return el
          })
          data.sort((a, b) => (b.color > a.color) ? 1 : -1)
          setData(data)
          setLoadded(false)
        }).catch(err=>{
          enqueueSnackbar('Serveur indisponible', {variant:"error"});
        }) 
  }

  const onFormEdit = (data) =>{
    axios.put(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command_lists/${data.listID}`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        enqueueSnackbar('Commande editée avec succès', {variant:"success"});
        getData()
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const onRowClick = (row) => {
    setselectedList(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedList(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
  }

  const handleOpenDialog = (editPs) => {
    const isEdit = editPs[1]? true : false
    setDialogState(true)
    setEditPs(editPs)
    setIsEdit(isEdit)
  };
  
  const handleCloseDialog = () => {
    setDialogState(false)
    setEditPs(null)
    setIsEdit(false)
  };
     
  return (
    <React.Fragment>
      {showDetail ? 
          <Detail //detail.js > table_command.js > CustomToolbarSelectCommand.js
              selectedList = {selectedList}
              onCloseDetail = {onCloseDetail}
              psInfos = {token.psInfos}
              isAdmin = {token.isAdmin}
              mobile = {mobile}
            />
            :
      <div style={{marginBottom: "2rem"}}>
        <Toolbar/>
        <AddDialog
            dialogState={dialogState}
            handleClose={handleCloseDialog}
            onFormEdit={onFormEdit}
            editPs={editPs}
            isEdit={isEdit}
            />
        {loadded &&
          <LinearProgress />
        }    
        <TablePieces //table.js > CustomToolbarSelectList.js
            data={data}
            onRowClick = {onRowClick}
            onListDelete = {onListDelete}
            onListArchive = {onListArchive}
            onEditSubmit = {handleOpenDialog}
            isAdmin = {token.isAdmin}
            />
      </div>        
      }

    </React.Fragment>
  );
}