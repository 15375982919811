import React, {useState, useEffect, Component} from 'react';
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import { FixedSizeList as List } from "react-window";


export default function SelectModule(props) {

  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState([])

  useEffect(() => {
    if(props.update){
        const ops = props.data.map((el) => {
            const obj = {}
            obj["value"]= el.psID
            obj["label"]= (el.codeMagasin ? el.codeMagasin + " " : "") + el.name
          return obj
          })
          setOptions(ops)
    }
  }, [props.update]);

  useEffect(() => {
      setSelectedOption([props.selectedOption])
  }, [props.selectedOption]);

    return (
      <FormGroup style={{width: "100%", marginRight: "10px", marginBottom: "20px"}}>
      <Select
        autoFocus
        value={options.filter((el)=>{
          return selectedOption.includes(el.value)
        })}
        onChange={props.onChange}
        options={options}
        components={{ MenuList }}
        menuPosition="fixed"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        inputId= 'bulkInput'
        placeholder="Rechercher une pièce par code magasin..."
      />
      </FormGroup>
    );
}

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={35}   
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}