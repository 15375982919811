import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbarSelect from "./CustomToolbarSelect";

class Table extends Component {
  constructor(props){
    super()
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.data !== nextProps.data) || (this.props.mobile !== nextProps.mobile)
  }

  onFormSubmit = (data) => {
    this.props.onFormSubmit(data)
  }

  render(){

  const columns = [
    {
      name: "psID", //0
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
    {
      name: "code", //1
      label: "Référence",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "exCode", //2
      label: "ex Reference",
      options: {
       filter: false,
       sort: false,
       display: false
      }
     },
    {
     name: "name", //3
     label: "Désignation",
     options: {
      filter: false,
      sort: true,
      searchable: true,
     }
    },
    {
      name: "fournisseur", //4
      label: "Fournisseur",
      options: {
       filter: true,
       sort: true,
       display: !this.props.mobile,
      }
     },

     {
      name: "machine", //5
      label: "Equipement",
      options: {
       filter: true,
       sort: true,
       display: !this.props.mobile,
      }
     },
     {
      name: "zone", //6
      label: "Zone",
      options: {
       filter: true,
       sort: true,
       display: !this.props.mobile,
      }
     },
    {
     name: "codeMagasin", //7
     label: "Code magasin",
     options: {
      filter: false,
      sort: false,
      display	: false
     }
    },
    {
      name: "posMagasin", //8
      label: "Position Magasin",
      options: {
       filter: true,
       sort: true,
       display	: true
      }
     },
    {
     name: "postav", //9
     label: "TAV/POS",
     options: {
      filter: false,
      sort: true,
      display	: false
     }
    },
     {
      name: "qte", //10
      label: "Qté Magasin",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     }, 
     {
      name: "count", //11
      label: "Conso annuelle",
      options: {
       filter: true,
       sort: false,
       searchable: false,
       display: true,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "acommander", //12
      label: "Qté à commander",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     }, 
     {
      name: "annuelle", //13
      label: "Conso Estimée",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "alerte", //14
      label: "Quantité d'alerte",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
     {
      name: "price", //15
      label: "Prix unitaire",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       display	: false,
       setCellProps: () => ({style:{textAlign: 'right'}}),
       setCellHeaderProps: () => ({style:{textAlign: 'right'}}),
      }
     },
     {
      name: "unit", //16
      label: "unit",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: "excluded",
      }
     },
     {
      name: "currency", //17
      label: "Devise",
      options: {
       filter: true,
       sort: false,
       searchable: false,
       display: false
      }
     },
   ];

   const options = {
     search: true,
     searchOpen: true,
     searchPlaceholder: "Rechercher par Désignation, Référence, Machine...",
     print: true,
     download: true,
     rowsPerPage: 90,
     rowsPerPageOptions: [60, 90, 120, 180],
     responsive: 'scrollFullHeight',
     filterType: 'textField',
     searchText: this.props.searchText,
     rowsSelected: this.props.rowsSelected,
     customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect 
        selectedRows={selectedRows} 
        onFormSubmit={this.onFormSubmit} 
        displayData={displayData} 
        setSelectedRows={setSelectedRows}
        onChangePanier = {this.props.onChangePanier}
        />
    ),
     onCellClick: (a,meta) => {
      if(meta.colIndex!==1){
        this.props.onRowClick(this.props.data[meta.dataIndex].psID)
      }
     },
     onSearchChange: (searchText) => {
      this.props.onSearchTextChange(searchText)
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Articles par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Pièces(s) sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },
    setRowProps: (row) => {
      let style = {}
      if(row[10] <= 0){
        style["color"] = "white"
        style["backgroundColor"] = "#ff4040"
      }else if(row[10] <= row[14]){
        style["backgroundColor"] = "#ffa926"
      }
      return {
        style: style
      }
   }
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
          fontSize: "0.8rem"
        }
      }
    }
  })
   
  return (
<MuiThemeProvider theme={getMuiTheme()}>
  <MUIDataTable
    title={"Liste à commander"}
    data={this.props.data}
    columns={columns}
    options={options}
  />
</MuiThemeProvider>
  );
}
}

export default Table;
