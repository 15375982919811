import React, { Component , Fragment } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';


class Tableau extends Component {
  constructor(props){
    super()
  }

  render(){
  const columns = [
    {
      name: "comID",
      label: "id",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "psID",
      label: "psID",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "listID",
      label: "listID",
      options: {
       filter: false,
       sort: false,
       display: "excluded",
       searchable: false,
      }
     },
     {
      name: "code",
      label: "Référence",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "postav",
      label: "TAV/POS",
      options: {
       filter: false,
      }
     },
     {
      name: "piece",
      label: "Piece",
      options: {
       filter: true,
       sort: true,
       display: true
      }
     },
     {
      name: "codeMagasin",
      label: "Code magasin",
      options: {
       filter: false,
       sort: false,
       display	: false
      }
     },
     {
       name: "posMagasin",
       label: "Position Magasin",
       options: {
        filter: false,
        sort: false,
        display	: false
       }
      },
     {
      name: "machine",
      label: "Machine/Equipement",
      options: {
       filter: false,
       sort: true,
      }
     },
    {
      name: "qte",
      label: "Quantité",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
     {
      name: "unit",
      label: "Unité",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display	: true,
      }
     },
     {
      name: "price",
      label: "Prix unitaire",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellProps: () => ({style:{textAlign: 'left'}}),
       setCellHeaderProps: () => ({style:{textAlign: 'left'}}),
      }
     },
     {
      name: "montant",
      label: "Montant",
      options: {
       filter: false,
       sort: true,
       searchable: false,
       setCellProps: () => ({style:{textAlign: 'left'}}),
       setCellHeaderProps: () => ({style:{textAlign: 'left'}}),
      }
     },
    {
      name: "state",
      label: "State",
      options: {
       filter: false,
       sort: false,
       searchable: false,
       display: "excluded",
       setCellHeaderProps: () => ({style:{textAlign: 'center'}}),
       setCellProps: () => ({style:{textAlign: 'center', fontSize: '0.95rem'}}),
      }
     },
   ];
 
   const options = {
     search: true,
     searchOpen: true,
     searchPlaceholder: "Rechercher par Désignation, Référence, Machine...",
     print: false,
     download: false,
     filter: false,
     selectableRows: 'none',
     rowsPerPage: 60,
     rowsPerPageOptions: [30, 60, 90, 120],
     responsive: 'scrollFullHeight',
     onCellClick: (a,meta) => {
       if(meta.dataIndex || meta.dataIndex===0){
        this.props.onRowClick(this.props.data[meta.dataIndex].psID)
       }
     },
     textLabels: {
      body: {
        noMatch: "Aucune pièce correspondante trouvée",
        toolTip: "Classer",
        columnHeaderTooltip: column => `Classer par ${column.label}`
      },
      pagination: {
        next: "Page Suivante",
        previous: "Page Précedente",
        rowsPerPage: "Articles par page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger les données",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tout",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "Pièces(s) sélectionnée(s)",
        delete: "Suprimer",
        deleteAria: "Suprimer les pièces(s) sélectionnée(s)",
      },
    },
  }

   const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontWeight: "bold",
          color: "inherit",
        }
      }
    }
  })

  const subtotal = (items) => {
    return items.reduce((sum, i) => sum + parseFloat(i.montant), 0).toFixed(2);
  }

  const invoiceSubtotal = subtotal(this.props.data);

  
  return (
    <Fragment>
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Liste des pièces de la commande"}
        data={this.props.data}
        columns={columns}
        options={options}
      />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
    </MuiThemeProvider>
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
        <TableContainer component={Paper} style={{maxWidth: "350px"}}>
          <Table>
          <TableRow>
              <TableCell className="total">Montant Total</TableCell>
              <TableCell align="right" className="total">{invoiceSubtotal +" "+ this.props.currency}</TableCell>
          </TableRow>
        </Table>
          </TableContainer>
        </div>    
    </Fragment>
  );
}
}

export default Tableau;
