import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import SelectModule from './select'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectAncien from './select_zone'
import Confirm from '../confirmDialog'
import { useSnackbar } from 'notistack';
import * as moment from 'moment-timezone'

import Resizer from 'react-image-file-resizer';
import axios from 'axios'

moment.tz.setDefault("Africa/Algiers");

export default function AddDialog(props) {
  const initialState = {
    psID: '',
    name: '',
    code: '',
    exCode: '',
    pos: '',
    tav: '',
    posMagasin: '',
    codeMagasin: '',
    price: 0,
    qte: 0,
    unit: 'U',
    annuelle: 0,
    currency: 'E',
    alerte: 0,
    type: 'Standard',
    zone: ''
  }

  const { enqueueSnackbar } = useSnackbar();
  
  const handleChange = (event) => {
    const {name, value} = event.target
    setText({...text, [name]: value})
  }

  const handleClose = () => {
    props.handleClose()
    setText(initialState)
    setSelectedZone()
    setSelectedMachine()
    setSelectedFournisseur()
    setSelectedFileUri("aucun fichier selectionner")
    if(isEdit){
      document.getElementById("imageFileInput").value = "";
    }
    setImageFile(null)
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    let data = text
    data["name"] = name.toUpperCase()
    data["zone"] = selectedZone
    data["fournisseurID"] = selectedFournisseur
    data["machineID"] = selectedMachine
    data["posMagasin"] = posMagasin.toUpperCase()
    data["postav"] = tav+";"+pos
    data["price"] = price ? parseFloat(price) : 0
    data["qte"] = qte ? parseFloat(qte) : 0
    data["annuelle"] = annuelle ? parseFloat(annuelle) : 0
    data["alerte"] = alerte ? parseFloat(alerte) : 0
    data["delta"] = parseFloat(qte)
    data["newQte"] = parseFloat(qte)
    data["action"] = 4
    data["date"] = moment().toDate()
    data["type"] = type==="Standard"?0:1
    props.onFormSubmit(data)
    props.handleClose()
    setSelectedZone()
    setSelectedMachine()
    setSelectedFournisseur()
    setSelectedFileUri("aucun fichier selectionner")
    if(isEdit){
      document.getElementById("imageFileInput").value = "";
    }
    setImageFile(null)
    setText(initialState)
  }

  const handleEdit = async(event) => {
    event.preventDefault()
    let data = text
    data["name"] = name.toUpperCase()
    data["zone"] = selectedZone
    data["fournisseurID"] = selectedFournisseur
    data["machineID"] = selectedMachine
    data["posMagasin"] = posMagasin ? posMagasin.toUpperCase() : ''
    data["postav"] = tav+";"+pos
    data["price"] = price ? parseFloat(price) : 0
    data["qte"] = qte ? parseFloat(qte) : 0
    data["annuelle"] = annuelle ? parseFloat(annuelle) : 0
    data["alerte"] = alerte ? parseFloat(alerte) : 0
    data["type"] = type==="Standard"?0:1  
    
    props.onFormEdit(data)
     
    if(qte !== props.editPs[10]){
      data["delta"] = parseFloat(qte)-props.editPs[10]
      data["newQte"] = parseFloat(qte)
      data["action"] = 3
      data["date"] = moment().toDate()
      axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/historiques/${data.psID}`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
    }

    if(imageFile){
      handleUploadImage()
    }
    props.handleClose()
    setSelectedZone()
    setSelectedMachine()
    setSelectedFournisseur()
    setSelectedFileUri("aucun fichier selectionner")
    setImageFile(null)
    setText(initialState)
    document.getElementById("imageFileInput").value = "";
  }

  const handleDelete = (event) => {
    props.onDelete(text.psID)
    props.handleClose()
    setSelectedZone()
    setSelectedMachine()
    setSelectedFournisseur()
    setSelectedFileUri("aucun fichier selectionner")
    document.getElementById("imageFileInput").value = "";
    setImageFile(null)
    setText(initialState)    
  }

  const handleSelectMachine = selected => {
    setSelectedMachine(selected.value)
  }

  const handleSelectFournisseur = selected => {
    setSelectedFournisseur(selected.value)
  }

  const handleSelectZone = selected => {
    setSelectedZone(selected.value)
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }

  var fileInput = false
  const handleFileConvert = (event) => {
    if(event.target.files[0]) {
        fileInput = true
        setSelectedFileUri(event.target.files[0].name)
    }
    if(fileInput) {
        Resizer.imageFileResizer(
            event.target.files[0],
            2000,
            2000,
            'JPEG',
            85,
            0,
            uri => {
              setImageFile(dataURLtoFile(uri, psID + '.jpg'))
            },
            'base64'
        );
    }
  }

  
  const handleUploadImage = async() =>{
    const data = new FormData()
    data.append('file', imageFile, imageFile.name)
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/photoUpload`, data, {headers: {"auth-token" : localStorage.getItem('token')}})
    .then(res => {
        enqueueSnackbar('Photo télechargée avec succès', {variant:"success"});
    }).catch(err=>{
      enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
    })
  }
  

  useEffect(() => {
     setDialogState(props.dialogState)
   }, [props.dialogState])

   useEffect(() => {
    if(props.isEdit){
      setText({
        psID: props.editPs[0],
        code: props.editPs[1],
        exCode: props.editPs[2],
        name: props.editPs[3],
        codeMagasin: props.editPs[7],
        posMagasin: props.editPs[8],
        tav: props.editPs[9]?props.editPs[9].split(" POS.")[0].slice(4) : "",
        pos: props.editPs[9]?props.editPs[9].split(" POS.")[1] : "",
        qte: props.editPs[10],
        count: props.editPs[11],
        annuelle: props.editPs[12],
        alerte: props.editPs[13],
        price: props.editPs[14],
        unit: props.editPs[15],
        currency: props.editPs[16]==="€"?"E":"D",
        type: props.editPs[20],
      })
      setSelectedMachine(props.editPs[17])
      setSelectedZone(props.editPs[6])
      setSelectedFournisseur(props.editPs[18])
      if(img.width > 0){
        setSelectedFileUri(psID+".jpg")
      }

    }
   }, [props.isEdit])

   const [dialogState, setDialogState] = useState(props.dialogState)

   const [text, setText] = useState(initialState)

   const {psID, name, code, exCode, codeMagasin, posMagasin, pos, tav, price, qte, unit, currency, annuelle, alerte, type} = text

   const [selectedFournisseur, setSelectedFournisseur] = useState(null)

   const [selectedZone, setSelectedZone] = useState(null)

   const [selectedMachine, setSelectedMachine] = useState(null)

   const [selectedFileUri, setSelectedFileUri] = useState("aucun fichier selectionner")

   const [imageFile, setImageFile] = useState(null)

   const {isEdit} = props

   var img = new Image()
   img.src = "${window.location.protocol}//"+window.location.hostname+":"+window.location.port+"/photo/"+psID+".jpg"
   //img.src = "${window.location.protocol}//"+window.location.hostname+":${window.location.port}/photo/"+psID+".jpg"

   img.onload = function()
   {
    setSelectedFileUri("Photo disponible: "+psID+".jpg")
   }

   return (
    <div>
      <Dialog 
          open={dialogState} 
          onClose={handleClose} 
          fullWidth 
          fullScreen={props.mobile}
          aria-labelledby="form-dialog-title"
         >
        <DialogContent style={{marginBottom: "2rem"}}>
        {isEdit?
            <Typography variant="h6" gutterBottom >Editer la piéce {name}</Typography>:
            <Typography variant="h6" gutterBottom >Ajouter une nouvelle pièce de rechange</Typography>
          }

          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Nom de la pièce de rechange"
            name="name"
            value={name}
            onChange={handleChange}
            fullWidth
          />
          <SelectModule 
            title="Machine"
            onChange={handleSelectMachine} 
            selectedOption={selectedMachine} 
            update={dialogState}
            from="machines"
          /> 

          <div style={{display: "flex", justifyContent: "space-between"}}>
          <SelectModule 
            title="Fournisseur"
            onChange={handleSelectFournisseur} 
            selectedOption={selectedFournisseur} 
            update={dialogState}
            from="fournisseurs"
          /> 
          <SelectAncien 
            title="Zone"
            onChange={handleSelectZone} 
            selectedOption={selectedZone} 
            update={dialogState}
            from="zones"
          /> 
          </div>

          <InputLabel style={{margin: "15px 0px 5px"}}>Informations fournisseur</InputLabel>
          <div style={{display: "flex", width: "100%",justifyContent: "space-arround"}}>
          <TextField
            margin="dense"
            id="code"
            label="Code fournisseur"
            name="code"
            value={code}
            onChange={handleChange}
            variant="outlined"
            style={{marginRight: "10px"}}
            fullWidth
          />
          <TextField
            margin="dense"
            id="exCode"
            label="Ex Code"
            name="exCode"
            variant="outlined"
            value={exCode}
            onChange={handleChange}
            style={{marginRight: "10px"}}
            fullWidth
          />
          <div style={{display: "flex", width: "580px"}}>
          <TextField
            margin="dense"
            id="tav"
            label="Tav"
            name="tav"
            variant="outlined"
            value={tav}
            onChange={handleChange}
            style={{marginRight: "10px"}}
            fullWidth
          />
          <TextField
            margin="dense"
            id="pos"
            label="Pos"
            name="pos"
            variant="outlined"
            value={pos}
            onChange={handleChange}
            style={{marginRight: "5px"}}
            fullWidth
          />
          </div>
          </div>
          <InputLabel style={{margin: "10px 0px 5px"}}>Informations magasin</InputLabel>
          <div style={{display: "flex", width: "100%",justifyContent: "space-arround"}}>
          <TextField
            margin="dense"
            id="codeMagasin"
            label="Code magasin"
            name="codeMagasin"
            variant="outlined"
            value={codeMagasin}
            onChange={handleChange}
            style={{marginRight: "5px"}}
            fullWidth
          />

          <TextField
            margin="dense"
            id="posMagasin"
            label="Position Magasin"
            name="posMagasin"
            variant="outlined"
            value={posMagasin}
            onChange={handleChange}
            style={{marginRight: "5px"}}
            InputProps={{ inputProps: { min: 0} }}
            fullWidth
          />
           </div>

          <InputLabel style={{margin: "10px 0px 5px"}}>Informations stock</InputLabel>
          <div style={{display: "flex"}}>
          <TextField
            margin="dense"
            id="qte"
            label="Quantité"
            name="qte"
            variant="outlined"
            type="number"
            value={qte}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
              inputProps: { min: 0}
            }}
            style={{marginRight: "5px"}}
            fullWidth
          />          
          <TextField
            margin="dense"
            id="annuelle"
            label="Qté annuelle estimée"
            name="annuelle"
            variant="outlined"
            type="number"
            value={annuelle}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
              inputProps: { min: 0}
            }}
            style={{marginRight: "5px"}}
            fullWidth
          />
          <TextField
            margin="dense"
            id="alerte"
            label="Qté d'alerte"
            name="alerte"
            variant="outlined"
            type="number"
            value={alerte}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
              inputProps: { min: 0}
            }}
            style={{marginRight: "5px"}}
            fullWidth
          />
          </div>

      <FormControl fullWidth margin="dense" variant= "outlined">
        <Select
            id="unit"
            name="unit"
            onChange={handleChange}
            value={unit}
          >
            <MenuItem value="U">Unitaire</MenuItem>
            <MenuItem value="ML">Mètre linéaire (ML)</MenuItem>
            <MenuItem value="KG">Kilogrammes (KG)</MenuItem>
        </Select>
      </FormControl>

      <div style={{display: "flex"}}>
      <TextField
            margin="dense"
            id="price"
            label="Prix Unitaire"
            name="price"
            variant="outlined"
            type="number"
            value={price}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 0} }}
            style={{marginRight: "5px"}}
            fullWidth
      />

      <FormControl margin="dense" variant= "outlined" style={{width: "20%"}}>
      <Select
            id="priceUnit"
            name="currency"
            onChange={handleChange}
            value={currency}
        >
            <MenuItem value="E">€</MenuItem>
            <MenuItem value="D">DZD</MenuItem>
        </Select>
        </FormControl>
          </div>

        <InputLabel style={{marginTop: "10px"}}>Type de pièce</InputLabel>
        <FormControl fullWidth margin="dense" variant= "outlined">
        <Select
          id="type"
          name="type"
          onChange={handleChange}
          value={type}
        >
            <MenuItem value={"Standard"}>Pièce standard</MenuItem>
            <MenuItem value={"Importante"}>Pièce importante</MenuItem>
        </Select>
        </FormControl>  
        
        {isEdit && 
        <React.Fragment>
        <InputLabel style={{margin: "15px 0px 15px"}}>Photo de la pièce</InputLabel>
        <div style={{display:"flex"}}>
        <Button
          variant="outlined"
          component="label"
        >
        Choisir un fichier
        <input
          type="file"
          accept=".jpg,.jpeg,.png,.gif,.tiff"
          id="imageFileInput"
          onChange={handleFileConvert}
          style={{ display: "none" }}
        />
        </Button>
          <p style={{fontSize: "14px", marginLeft: "1rem"}}>{selectedFileUri}</p>
        </div>

        <Confirm title={`Etes-vous sûr de vouloir supprimer cette pièce de rechange ?`} action="Suprimer">
          {confirm => (
          <Button onClick={confirm(()=>handleDelete())} fullWidth variant="outlined" color="secondary" style={{marginTop: "1rem"}}>
              Supprimer la pièce de rechange
          </Button>
          )}
         </Confirm>
         </React.Fragment>
        }

        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>

          {isEdit? 
            <Button onClick={handleEdit} color="primary" disabled={text.name === ""}>
            Editer
          </Button> :
            <Button onClick={handleSubmit} color="primary" disabled={text.name === ""}>
            Ajouter
          </Button>
          }  
          </DialogActions>
      </Dialog>
    </div>
  );
}
