import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import axios from 'axios';


export default function SelectModule(props) {

  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [isLoading, setIsLoadding] = useState(false)

  useEffect(() => {
    if(props.update){
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/${props.from}`, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        const ops = res.data.map((el) => {
          const obj = {}
          obj["value"]= el.name
          obj["label"]= el.name
        return obj
        })
        setOptions(ops)
      })
    }
  }, [props.update]);


  useEffect(() => {
      setSelectedOption([props.selectedOption])
  }, [props.selectedOption]);

    return (
      <FormGroup style={{width: "100%", marginTop: "10px"}}>
      <InputLabel style={{marginBottom: "8px"}}>{props.title}</InputLabel>
      <Select
        value={options.filter((el)=>{
          return selectedOption.includes(el.value)
        })}
        onChange={props.onChange}
        options={options}
        isDisabled={isLoading}
        menuPosition="fixed"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        placeholder="Selectioner..."
      />
      </FormGroup>
    );
}