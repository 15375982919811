import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import TableHistory from './../historique/table'
import TableCommande from './../command_list/table_commande'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import * as moment from 'moment'
import 'moment/locale/fr'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

moment.locale('fr')

function createData(name, infos) {
    return {name, infos};
  }

const getColor = (qte, alerte) =>{  
    let color = "black"
    if(qte<=0) color =  "red"
    else if(qte<=alerte) color =  "#de7a00"
    return {
        color: color,
    }
}

function Tabl(props) {
    const {title, qte, alerte} = props
return(
<div style={{flexGrow: "1", marginRight: "2%", marginBottom: "3rem", maxWidth: "400px"}}>
    <h2>{title}</h2>
    <div>
    <Table aria-label="simple table">
    <TableBody >
        {props.rows.map(row => (
        <TableRow hover key={row.name}>
            <TableCell className="detail" component="th" scope="row">
            {row.name}
            </TableCell>
            {row.name === "Quantité" ? 
                <TableCell className="detail" style={getColor(qte, alerte)} align="right">{row.infos}</TableCell>
                :
                <TableCell className="detail" align="right">{row.infos}</TableCell>
            }
            
        </TableRow>
        ))}
    </TableBody>
    </Table>
</div>
</div>
)
}

export default function Detail(props) {

        
    const [data, setData] = useState([])
    const [history, setHistory] = useState([])
    const [commandes, setCommandes] = useState([])
    const [trimestre, setTrimestre] = useState(0)
    const [semestre, setSemestre] = useState(0)
    const [annee, setAnnee] = useState(0)
    const [tab, setTab] = useState(0)
    const [isImage, setIsImage] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
 
    const fournisseur = [
        createData('Référence fournisseur', data.code),
        createData('Ex code', data.exCode),
        createData('Machine', data.machine + " " + data.machineCode),
        createData('TAV/POS', data.postav),
        createData('Fournisseur', data.fournisseur),

      ];
    
    const fournisseurInfos = [
        createData('Prix unitaire', data.price + " " + data.currency)
    ]
    
    const magasin = [
        createData('Code magasin', data.codeMagasin),
        createData('Position magasin', data.posMagasin),
        createData('Quantité', data.qte + " " + data.unit),
    ];

    const magasinInfos = [
        createData('Conso annuelle estimée', data.annuelle + " " + data.unit),
        createData('Quantité d\'alerte', data.alerte + " " + data.unit),
        createData('Zone', data.zone),
    ]

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    const photoExist = (url) => {
        const http = new XMLHttpRequest()
        http.open('HEAD', url, false)
        http.send()
        setIsLoading(http.status!=404)
        setIsImage(http.status!=404)
    }

    useEffect(()=>{
        axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/piece/${props.selectedPS}`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
            const dat = res.data
            let color = 0
            if(res.data.qte<=0) color =  2
            else if(res.data.qte<=res.data.alerte) color =  1

            dat["color"] = color
            if(res.data.machine){
                dat["machineCode"] =  res.data.machine.code ? res.data.machine.code : " "
                dat["machine"] =  res.data.machine.name ? res.data.machine.name : " "
            }else{
                dat["machineCode"] =  ""
                dat["machine"] =  ""
            }
            if(res.data.fournisseur){
                dat["fournisseur"] =  res.data.fournisseur.name ? res.data.fournisseur.name : ""
            }
            dat["currency"] = res.data.currency==="E"?"€":"DZD"

            if(res.data.postav){
                const postav = res.data.postav.split(";")
                dat["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            setData(dat)

            photoExist(window.location.protocol+"//"+window.location.hostname+":"+window.location.port+"/photo/"+dat.psID+".jpg")
            //photoExist(`http://localhost:${window.location.port}/photo/${dat.psID}.jpg`)

            let his = res.data.historiques.map((el)=>{
                const date = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSS ZZ')
                el['delta'] = el.delta +" "+ dat.unit
                el['newQte'] = el.newQte +" "+ dat.unit
                el['piece'] = dat.name
                el['date'] = date.format("DD MMMM YYYY")
                el["sort"] = date.format('YYYYMMDD')
                return el
              })
            his = his.sort((a,b) => (b.sort - a.sort) || (a.action - b.action))  
            setHistory(his)

            let comm = res.data.commandes.map((el)=>{
                let item = {}
                const date = moment(el.command_list.date, 'YYYY-MM-DD HH:mm:ss.SSS ZZ')
                item["commande"] = el.command_list.name
                item["qte"] = el.qte + " " + res.data.unit
                item["price"] = el.price.toFixed(2) + " " + res.data.currency
                const montant = el.price * el.qte
                item["montant"] = montant.toFixed(2) + " " + res.data.currency
                item["state"] = el.state
                item["comment"] = el.comment 
                item["date"] = date.format("DD MMMM YYYY")
                item["sort"] = date.format('YYYYMMDD')

                return item
              })
            comm = comm.sort((a,b) => b.sort - a.sort)
            setCommandes(comm)
        }).then(res1 => {
            return axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/ps_count/${props.selectedPS}/3`)
            .then(res => {
                if(res.data[0].count) {
                    if(res.data[0].count % 1 === 0){
                        setTrimestre(res.data[0].count)
                    }else{
                        setTrimestre(res.data[0].count.toFixed(2))
                    }                        
                }
                else setTrimestre(0)
        })            
        }).then(res => {
            return axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/ps_count/${props.selectedPS}/6`)
            .then(res => {
                if(res.data[0].count) {
                    if(res.data[0].count % 1 === 0){
                        setSemestre(res.data[0].count)
                    }else{
                        setSemestre(res.data[0].count.toFixed(2))
                    }                        
                }
                else setSemestre(0)
        })      
        }).then(res => {
            axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/ps_count/${props.selectedPS}/12`)
            .then(res => {
                if(res.data[0].count) {
                    if(res.data[0].count % 1 === 0){
                        setAnnee(res.data[0].count)
                    }else{
                        setAnnee(res.data[0].count.toFixed(2))
                    }                        
                }
                else setAnnee(0)
            })         
        })        
            
    }, [])

    const url = window.location.protocol+"//"+window.location.hostname+":"+window.location.port+"/photo/"+data.psID+".jpg"
    //const url = window.location.protocol+"//"+window.location.hostname+":${window.location.port}/photo/"+data.psID+".jpg"

    const loadded = ()=> {
        setIsLoading(false)
    }

    return(
    <div style={{marginBottom: "3rem"}}>
    {props.mobile ||
        <Button onClick={()=>props.onClose()} title="Fermer" variant="outlined" color="primary" style={{position: "absolute", top:"20px", right: "30px", cursor: "pointer", zIndex:"99"}}>
            <CloseIcon color="primary"/>   
        </Button>
    }
    <div style={{textAlign:"center"}}>
    <h4 style={{color: "grey"}}>Détail sur la pièce de rechange</h4>

    {data.name ?
        <h1>{data.name} {data.machine && <span>pour</span>} {data.machine}</h1>:
        <CircularProgress color="secondary"/>
    }
    <React.Fragment>
    <div>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start"}}>
            <Tabl rows={props.psInfos ? fournisseur.concat(fournisseurInfos) : fournisseur} title="Informations fournisseur"/>
            <Tabl rows={props.psInfos ? magasin.concat(magasinInfos) : magasin} qte={data.qte} alerte={data.alerte} title="Informations magasin"/>
        {isImage &&
        <div style={{display: isLoading?"none":"flex", flexDirection: "column", height: "400px", alignItems: "center", justifyContent: "center"}}>
            <Zoom>
                <img
                    src={url}
                    style={{maxHeight: "450px", maxWidth: "420px"}}
                    onLoad={loadded}
                />
            </Zoom>
            <a href={url} download={data.codeMagasin+".jpg"} target="_blank" style={{textDecoration: "none"}}>
                <Button>Télécharger l'image</Button>
            </a>
          </div>
        }
        {isLoading &&
            <div style={{display: "flex", width:"350px", flexDirection: "column", height:"400px", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress /> 
                Chargement de la photo
            </div>
        }
        </div>
    </div>
    </React.Fragment>
    </div>
    {props.psInfos &&
    <React.Fragment>
    <h2 style={{textAlign: "center", marginTop: "2rem"}}>Consommation de la piece</h2>
    <TableContainer style={{width: "40rem", maxWidth: "100%",margin: "auto", textAlign: "center"}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" className="detail">Trimestre</TableCell>
            <TableCell align="center" className="detail">Semetre</TableCell>
            <TableCell align="center" className="detail">Année</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell align="center" className="detail">{trimestre}</TableCell>
              <TableCell align="center" className="detail">{semestre}</TableCell>
              <TableCell align="center" className="detail">{annee}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
        

    <Tabs
        style={{marginTop: "2.5rem"}}
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab style={{fontWeight: "bold", fontSize: "13px"}} label="Historique des entrées/sorties" value={0}/>
        {props.isAdmin &&
            <Tab style={{fontWeight: "bold", fontSize: "13px"}} label="Historique des commandes"  value={1}/>
        }
    </Tabs>
    <div style={{width: "100%", margin: "auto"}}>
        {tab === 0 ? 
            <TableHistory 
                data={history}
                detailVersion
            />
            :
            <TableCommande
                data={commandes}
                detailVersion
            />
        }
    </div>
    </React.Fragment>
    }
    {props.mobile &&
        <Button onClick={()=>props.onClose()} fullWidth variant="outlined" color="primary" style={{marginTop: "3rem", fontSize: "1.2rem"}}>
                    Fermer
        </Button>
    }
    </div>
    )
}