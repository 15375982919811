import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        flexGrow: 1,

     },
     buttonAdd:{
        marginRight: 10
    }

  }));

export default function Toolbar(props) {
    const classes = useStyles();

    return(
    <div className={classes.toolBar}>
        <h2>Liste des fournisseurs</h2>
        <Button variant="contained" title="Ajouer un nouveau fournisseur" color="primary" className={classes.buttonAdd} onClick={props.handleOpen}>
                <AddIcon/>
        </Button>
    </div>
    )
}