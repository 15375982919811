import React, {useState, useEffect} from 'react';
import TablePieces from './table'
import Toolbar from './toolbar'
import DetailDialog from './../list_pieces/detailDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import Decode from 'jwt-decode'
import { useSnackbar } from 'notistack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CommandDialog from './command_dialog'
import axios from 'axios'

export default function Acommander() {

  const [data, setData] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedPS, setselectedPS] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [panier, setPanier] = useState([])
  const [showPanier, setShowPanier] = useState(false)
  const [loadded, setLoadded] = useState(true)
  const [offline, setOffline] = useState(false)
  const [selectedList, setSelectedList] = useState(null)
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const raw_token = localStorage.getItem("token")
  let token
  if(raw_token) token = Decode(raw_token)  

  useEffect(()=>{
    getData()
    window.addEventListener('hashchange', handleOnHashChange);
    setPanier(JSON.parse(localStorage.getItem("panier")) || [])
    setSelectedList(JSON.parse(localStorage.getItem("panierList")) || null)
    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, [])

  const handleOnHashChange = () => {  
    if(window.location.hash !== "#modal"){
      onCloseDetail()
    }
  }; 
      
  const handleSubmit = (event) => {
    event.preventDefault()
    onFormSubmit(panier.map((item)=>{
      item["listID"] = selectedList
      return item
    }))
    onClosePanier()
    setSelectedList()
}

  const onSearchTextChange =  (text) => {
    setSearchText(text)
  }

  const handleSelectList = selected => {
    setSelectedList(selected.value)
  }

  const onChangePanier = (d) => {
    const newPanier = panier.concat(d.filter( ({psID}) => !panier.find(f => f.psID == psID) ))
    setPanier(newPanier)
    localStorage.setItem("panier", JSON.stringify(newPanier))
  }

  const handleCancel = () => {
    setPanier([])
    setSelectedList(null)
    onClosePanier()
    localStorage.removeItem("panier")
    localStorage.removeItem("panierList")
  }

  const onChangeQtePanier = (d) => {
    setPanier(d)
  }

  const onOpenPanier = () => {
    setShowPanier(true)
  }
  
  const onClosePanier = () => {
    setShowPanier(false)
    localStorage.setItem("panierList", JSON.stringify(selectedList))
    localStorage.setItem("panier", JSON.stringify(panier))
  };


  const onFormSubmit = (data) =>{    
    axios.post(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/command`, data, {headers: {"auth-token" : localStorage.getItem('token')} })
      .then(res => {
        setPanier([])
        localStorage.removeItem("panier")
        localStorage.removeItem("panierList")
        enqueueSnackbar('Pièces ajoutées à la commande', {variant:"success"});
      }).catch(err=>{
        enqueueSnackbar('La connexion avec le serveur a été perdue', {variant:"error"});
      })
  }

  const getData = ()=>{
    axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/history_count`)
    .then(cnt =>{
      axios.get(`${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/pieces`, {headers: {"auth-token" : localStorage.getItem('token')}})
        .then(res => {
          const data = res.data.map((el)=>{
            let color = 0
            if(el.qte<=0) color =  2
            else if(el.qte<=el.alerte) color =  1
            el["color"] = color
            el["currency"] = el.currency==="E"?"€":"DZD"
            if(el.machine) el['machine'] = el.machine.name
            if(el.fournisseur) el['fournisseur'] = el.fournisseur.name
            const acommander = el.annuelle - el.qte 
            el["acommander"] = acommander > 0 ? acommander : 0
            if(el.postav){
              const postav = el.postav.split(";")
              el["postav"] = "TAB."+postav[0]+" POS."+postav[1]
            }
            return el
          })
          let merge = mergeID(data,cnt.data)
          merge = merge.map((el)=>{
            if(el.count){
              const count = el.unit == "ML" ? el.count.toFixed(2) : el.count
              el["count"] = count
            }else{
              el["count"] = 0
            }
            return el
          })
          merge.sort((a, b) => (b.color > a.color) ? 1 : -1)
          setData(merge)
          setLoadded(false)
        }).catch(err=>{
          enqueueSnackbar('Serveur indisponible', {variant:"error"});
          setLoadded(false)
          setOffline(true)
        }) 
      })
  }

  const onRowClick = (row) => {
    setselectedPS(row)
    setShowDetail(true)
    window.location.assign("#modal")
  }

  const onCloseDetail = () => {
    setselectedPS(0)
    setShowDetail(false)
    window.history.pushState(null,null,'#');
  }

  const mergeID = (arr1,arr2) => {
    let merge = [];

    for(let i=0; i<arr1.length; i++) {
      merge.push({
       ...arr1[i], 
       ...(arr2.find((itmInner) => itmInner.psID === arr1[i].psID))}
      );
    }
    return merge;
  }
    
  return (
    <React.Fragment>
      <div style={{marginBottom: "2rem"}}>
        <Toolbar
            count={panier.length}
            onOpenPanier={onOpenPanier}
        />
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {token.psInfos}
            mobile = {mobile}
        />
        {loadded &&
           <LinearProgress />   
        }     
        <TablePieces 
            data={data}
            onRowClick = {onRowClick}
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            onChangePanier={onChangePanier}
            mobile = {mobile}
            />

        <CommandDialog
          dialogState={showPanier}
          handleClose={onClosePanier}
          onFormSubmit={onFormSubmit}
          onChangePanier={onChangeQtePanier}
          panier={panier}
          selectedList = {selectedList}
          handleSelectList = {handleSelectList}
          handleSubmit = {handleSubmit}
          handleCancel = {handleCancel}
          offline = {offline}
        />
        </div>    
  
    </React.Fragment>
  );
}