import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        flexGrow: 1,
     },
     buttonPanier:{
        marginRight: 15
    }

  }));

export default function Toolbar(props) {
    const classes = useStyles();
    return(
    <div className={classes.toolBar}>
        <h2>Commander des pièces de rechange</h2>

        <Tooltip title={"Panier de commande"} className={classes.buttonPanier}>
        <Badge badgeContent={props.count} color="secondary" max={999}>
          <Button
            variant="contained"
            onClick={props.onOpenPanier}
            startIcon={<ShoppingCartIcon />}
            color="primary"
          >
            Commander
          </Button>
          </Badge>
        </Tooltip>
    </div>
    )
}