import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { makeStyles} from '@material-ui/core/styles'; 

const useStyles = makeStyles(theme => ({
    toolBar:{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        flexGrow: 1,
        flexWrap: "wrap",
        textAlign: "center",
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2),
          },
     },
     buttonAdd:{
        marginRight: 10
    },
    item:{
        flexGrow: "1", 
        margin: "auto"
    }
  }));

export default function Toolbar(props) {
    const classes = useStyles();
    return(
    <div className={classes.toolBar}>
        <h2>Liste des pièces de rechange</h2>
        {props.offline?
        <div style={{display: "flex", width: "100%", justifyContent: "center", height: "50px", color: "red"}}>
            <h2>Mode hors-ligne</h2>
        </div>
        :
        <div>
            {(props.token.isAdmin || props.token.change) &&  
            <div className={classes.item}>
                {props.token.isAdmin &&
                <Button variant="contained" title="Ajouer une nouvelle pièce de rechange" className={classes.buttonAdd} onClick={props.handleOpen}>
                    <AddIcon/>
                </Button>
                }
                {props.token.change &&
                <Button variant="contained" onClick={props.handleOpenBulkChange} color="primary" title="Déclarer des entrées / sorties">
                    <SwapHorizIcon/> 
                </Button>
                }
            </div>   
            }
        </div>
        }
    </div>
    )
}