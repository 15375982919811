import React, { useState, useEffect} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TablePieces from './table_commande'
import Toolbar from './toolbar_commandes'
import DetailDialog from './../list_pieces/detailDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import * as moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')


export default function Detail(props) {
  const [showDetail, setShowDetail] = useState(false)
  const [selectedPS, setselectedPS] = useState(0)
  const [rowsSelected, setRowsSelected] = useState([])
    
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(()=>{
        props.getData()
        window.addEventListener('hashchange', handleOnHashChange);
        return () => window.removeEventListener('hashchange', handleOnHashChange);
    }, [])

    const handleOnHashChange = () => {  
      if(window.location.hash !== "#detail"){
        onCloseDetail()
      }
    }; 

    const onRowClick = (row) => {
        setselectedPS(row)
        setShowDetail(true)
        window.location.assign("#detail")
      }

    const onCloseDetail = () => {
        setselectedPS(0)
        setShowDetail(false)
        window.location.replace('#modal')
    }

    const handleOpenDoc = () => {
      props.handleOpenDoc()
    }
    const handleOpenDocPrice = () => {
      props.handleOpenDocPrice()
    }   

    const onRowsSelected =  (rows) => {
        setRowsSelected(rows.map((el)=>{
          return el.dataIndex
        })
        )
      }


    return(
    <React.Fragment>
        <div style={{marginBottom: "2rem"}}>
        <ArrowBackIcon fontSize="large" style={{position: "absolute", top:"100px", cursor: "pointer"}} color="primary" onClick={()=>props.onCloseDetail()}/>   
        <div style={{marginLeft: "4rem", marginTop: "1.3rem"}}>
            <Toolbar
                name = {props.name}
                handleOpenDoc = {handleOpenDoc}
                handleOpenDocPrice = {handleOpenDocPrice}
            />
        </div>
        <DetailDialog
            selectedPS = {selectedPS}
            onClose = {onCloseDetail}
            dialogState = {showDetail}
            psInfos = {props.psInfos}
            mobile = {mobile}
            isAdmin = {props.isAdmin}
        />
        <TablePieces 
            data={props.data}
            currency={props.currency}
            onRowClick = {onRowClick}
            onDelete = {props.onDelete}
            onProformat = {props.onProformat}
            onRecu = {props.onRecu}
            onEdit = {props.onEdit}
            command = {props.name}
            total = {props.total}
            rowsSelected={rowsSelected}
            onRowsSelected={onRowsSelected}
            isAdmin = {props.isAdmin}
            mobile = {mobile}
            />
          </div> 
      </React.Fragment>
    )
}