import React from 'react';
import Pieces from './componements/list_pieces'
import History from './componements/historique'
import Machines from './componements/machines'
import Acommander from './componements/acommander'
import Command_list from './componements/command_list'
import Hist_commandes from './componements/hist_commandes'
import Fournisseurs from './componements/fournisseurs'
import SandBox from './componements/sandbox'
import DeadPS from './componements/dead_ps'
import Login from './login'
import Drawer from './componements/drawer'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import './App.css';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
     localStorage.getItem('account') !== null ? 
        <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />   
  )} />
);

const Application = () => {
  return(
      <Drawer>
            <ProtectedRoute path="/" exact component={Pieces}/>
            <ProtectedRoute path="/stock_mort" component={DeadPS}/>
            <ProtectedRoute path="/historique" component={History}/>
            <ProtectedRoute path="/machines" component={Machines}/>
            <ProtectedRoute path="/fournisseurs" component={Fournisseurs}/>
            <ProtectedRoute path="/commander" component={Acommander}/>
            <ProtectedRoute path="/commandes" component={Command_list}/>
            <ProtectedRoute path="/hist_commandes" component={Hist_commandes}/>
            <ProtectedRoute path="/sandBox" component={SandBox}/>
      </Drawer>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login" exact component={Login}/>
          <Route path="/login/:defaultAcc" exact component={Login}/>
          <ProtectedRoute path="/" component={Application}/>
        </Switch>
    </Router>
    </div>
  );
}

export default App;
